import { BrowserRouter as Router, Route, Switch, useRouteMatch, useParams } from "react-router-dom"
import LibrosList from "./LibrosList"
import FormaLibro from "./FormaLibro"


function Libros() {

    let match = useRouteMatch();

    return (
        <Router>
            <Route exact path={`${match.path}`}>
                <LibrosList />
            </Route>
            <Switch>
                <Route exact path={`${match.path}/agregar`}>
                    <FormaLibro />
                </Route>
                <Route exact path={`${match.path}/:libroId`}>
                    <FormaLibro mode='edit'></FormaLibro>
                </Route>
            </Switch>
        </Router>
    )
}

export default Libros