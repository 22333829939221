import React, { useState } from "react";
import { Button } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
import Requester from "./Requester";
import FileSaver from 'file-saver'
import IconButtonCustom from "./IconButtonCustom";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function b64toBlob(b64Data) {

    var byteString = atob(b64Data);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab]);
}

export default function FilePicker({ icon, children, onChange, fileUrl, customLabel, route, readOnly, disabled }) {

    const [uuid,] = useState(uuidv4)
    const [fileName, setFileName] = useState('')

    function handleChange(event) {

        if (!event.target.files || !event.target.files[0]) {
            return;
        }

        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onloadend = () => {
            const b64 = reader.result
                .replace("data:", "")
                .replace(/^.+,/, "");

            setFileName(file.name);
            onChange({
                name: file.name, 
                b64
            })

        };

        reader.readAsDataURL(file);
    }

    async function handleClickDownload() {
        const response = await Requester.get(`${route}/${fileUrl}`)
        FileSaver.saveAs(b64toBlob(response.data.file), response.data.name)
    }

    return (
        <div style={{ display: 'flex' }}>
            {
                fileUrl && !fileName ?
                    <div>
                        {
                            icon === undefined ?
                            <Button color="link" onClick={handleClickDownload}>{customLabel || 'Descargar'}</Button> :
                            <IconButtonCustom faIcon={faDownload} tooltip='Descargar' onClick={handleClickDownload}></IconButtonCustom>
                        }                                                
                    </div> : null
            }
            {
                !readOnly ?
                    disabled ?
                    <div>
                        <Button disabled>{customLabel || fileName || children}</Button>
                    </div> :
                    <div>
                        <input
                            type="file"
                            onChange={handleChange}
                            style={{ display: 'none' }}
                            id={uuid} />
                        <label htmlFor={uuid} style={{ marginBottom: 0 }}>
                            {
                                icon === undefined ?
                                <span className="btn btn-secondary"
                                style={{ cursor: 'pointer' }}>
                                    {
                                        (!fileUrl && customLabel) || fileName || children                                        
                                    }
                                </span> :
                                <div style={{marginLeft:'10px'}}>
                                    <IconButtonCustom faIcon={faUpload} tooltip={
                                    (!fileUrl && customLabel) || fileName || children                                        
                                    } color='black'></IconButtonCustom>
                                </div>
                            }
                        </label>
                    </div> : null
            }
        </div>
    )
}