import React, { useContext } from 'react';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import { SessionContext } from '../shared/session';
import IconButtonCustom from '../shared/IconButtonCustom';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const NotasDetail = ({ modulo, data, title }) => {

    const session = useContext(SessionContext);

    return (
        (session?.permisos?.admin || session?.permisos?.[modulo] === "read" || session?.permisos?.[modulo] === "write")
            ?
            <div>
                {data && data.notas && data.notas.length > 0 &&
                    <Container>
                        <Row>
                            <Col>
                                <h5>{title}</h5>
                            </Col>
                        </Row>
                        {data.notas.map((n, i) => {
                            return (
                                <Row key={i}>
                                    <Col xs={1}>
                                        <p>
                                            {n.fijo ? <IconButtonCustom faIcon={faCheck} tooltip="Asunto atendido" color="#86df8d" /> : null}
                                        </p>
                                    </Col>
                                    <Col xs={2}>
                                        {moment(n.fecha).format("DD-MM-YYYY")}
                                    </Col>
                                    <Col>
                                        <p>
                                            {n.nota}
                                        </p>
                                    </Col>
                                </Row>
                            )
                        })}
                    </Container>
                }
            </div> : null
    );
};

export default NotasDetail;