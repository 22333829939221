import { BrowserRouter as Router, Route, Switch, useParams, useRouteMatch } from "react-router-dom"
import FormaSeguimiento from "./FormaSeguimiento";
import SeguimientosList from "./SeguimientosList";



function Seguimientos() {

    let match = useRouteMatch();

    return (
        <>
            <Route exact path={`${match.path}`}>
                <SeguimientosList></SeguimientosList>
            </Route>
            <Switch>
                <Route exact path={`${match.path}/:seguimientoId`}>
                    <FormaSeguimiento></FormaSeguimiento>
                </Route>
            </Switch> 
        </>
    )
}

export default Seguimientos