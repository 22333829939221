import { BrowserRouter as Router, Route, Switch, useRouteMatch } from "react-router-dom"
import FormaUsuario from "./FormaUsuario";
import Permisos from "./Permisos";
import UsuariosList from './UsuariosList'


function Usuarios() {

    let match = useRouteMatch();

    return (
        <>
            <Route exact path={`${match.path}`}>
            <UsuariosList></UsuariosList>
            </Route>
            <Switch>
                <Route exact path={`${match.path}/agregar`}>
                    <FormaUsuario></FormaUsuario>
                </Route>
                <Route path={`${match.path}/:usuarioId/permisos`}>
                    <Permisos></Permisos>
                </Route>
                <Route exact path={`${match.path}/:usuarioId`}>
                    <FormaUsuario mode='edit'></FormaUsuario>
                </Route>
            </Switch>
        </>
    )
}

export default Usuarios