import React, { useEffect, useState, useContext } from "react"
import { useParams } from "react-router-dom";
import { Button, Col, Input, Label, Row, Table } from "reactstrap";
import Header from "../shared/Header";
import Requester from "../shared/Requester";
import usePost from "../shared/usePost";
import Swal from "sweetalert2";
// import { useContext } from "react/cjs/react.development";
import { SessionContext } from "../shared/session";

const MODULES_MEDIACION = [
    {
        label: 'Usuarios',
        name: 'usuarios'
    },
    {
        label: 'Clientes',
        name: 'clientes'
    },
    {
        label: 'Información Preliminar',
        name: 'infoPreliminar'
    }, {
        label: 'Actos Previos',
        name: 'actosPrevios'
    }, {
        label: 'Tramitación',
        name: 'tramitacion'
    }, {
        label: 'Pago y Facturación',
        name: 'pagoFacturacion'
    }, {
        label: 'Solicitud de Folio',
        name: 'solicitudFolio'
    }, {
        label: 'Firma',
        name: 'firma'
    }, {
        label: 'Actos Post-Firma',
        name: 'actosPostFirma'
    },  
    {
        label: 'Entrega',
        name: 'entrega'
    }, {
        label: 'Archivo y Copias',
        name: 'copiasArchivo'
    }
]

const MODULES_CORREDURIA = [...MODULES_MEDIACION.map(
    m => m.name === 'actosPostFirma' ? {
        label: 'Actos Post-Firma',
        name: 'actosPostFirmaCorreduria'
} : m )]

function Permisos() {

    const [permiso, setPermiso] = useState({ unidadNegocio: '' })

    const { usuarioId } = useParams()
    const session = useContext(SessionContext)

    const [usuario, setUsuario] = useState({})
    const [saving, savePermiso,] = usePost(`usuarios/permisos/${usuarioId}/${permiso.unidadNegocio}`)

    useEffect(() => {

        async function fetchPermiso() {

            const response = await Requester.get(`usuarios/permisos/${usuarioId}/${session.unidad._id}`)
            setPermiso({
                unidadNegocio: session.unidad._id,
                ...response.data
            })
        }

        if (session.unidad) {
            fetchPermiso()    
        }

    }, [session.unidad])

    useEffect(() => {

        async function fetchUsuario() {
            const response = await Requester.get(`usuarios/${usuarioId}`)
            setUsuario(response.data)
        }

        fetchUsuario();

    }, [usuarioId])


    function handleChangePermiso(module) {
        return function (e) {
            setPermiso({ ...permiso, [module.name]: e.target.value })
        }
    }

    function handleChangeUnidad(e) {
        setPermiso({ unidadNegocio: e.target.value })
    }

    function handleChangeAdmin(e) {
        setPermiso({ ...permiso, admin: e.target.checked })
    }

    async function handleClickSave() {
        savePermiso(permiso)
        Swal.fire({
            icon: 'success',
            title: 'Guardado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        })
    }

    return (
        <div>
            <Header>Permisos</Header>
            <div>
                <Row>
                    <Col xs='6'><strong>{usuario.nombre}</strong></Col>
                    <Col xs='6'>
                        <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                            <Label for="admin">Administrador</Label>
                            {' '}
                            <Input type="checkbox"
                                checked={permiso.admin}
                                onChange={handleChangeAdmin}></Input>
                        </div>
                    </Col>
                </Row>
                <br />
                {
                    permiso._id ?
                        <React.Fragment>
                            {
                                !permiso.admin ?
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Módulo</th>
                                                <th>Escritura</th>
                                                <th>Lectura</th>
                                                <th>Ninguno</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (session?.unidad?.tipo === 'Correduría' ? MODULES_CORREDURIA : MODULES_MEDIACION).map(m => (
                                                    <tr key={m.name}>
                                                        <td>{m.label}</td>
                                                        <td>
                                                            <input type="radio"
                                                                value="write"
                                                                checked={permiso[m.name] === 'write'} onChange={handleChangePermiso(m)}></input>
                                                        </td>
                                                        <td>
                                                            <input type="radio"
                                                                value="read"
                                                                checked={permiso[m.name] === 'read'} onChange={handleChangePermiso(m)}></input>
                                                        </td>
                                                        <td>
                                                            <input type="radio"
                                                                value="none"
                                                                checked={permiso[m.name] === 'none'} onChange={handleChangePermiso(m)}></input>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table> : null
                            }
                            <br />
                            <br />
                            <Row>
                                <Col xs="10"></Col>
                                <Col xs="2">
                                    <Button color="secondary"
                                        onClick={handleClickSave}
                                        disabled={saving}>Guardar</Button>
                                </Col>
                            </Row>
                        </React.Fragment> : null
                }

            </div>
        </div>
    )

}

export default Permisos