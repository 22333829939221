import { useEffect, useState } from "react";
import { Prompt, useParams } from "react-router";
import { Button, Container, Form, FormGroup, Row, Col, Table, Label } from "reactstrap";
import CheckboxDate from "../shared/CheckboxDate";
import FilePicker from "../shared/FilePicker";
import Header from "../shared/Header";
import Requester from "../shared/Requester";
import Subtitle from "../shared/Subtitle";
import usePost from "../shared/usePost";
import FormattedDatePicker from "../shared/FormattedDatePicker";
import SelectOne from "./components/SelectOne";
import Notas from "./Notas";
import moment from 'moment'
import Swal from "sweetalert2";
import AuthenticatedInput from "../shared/AuthenticatedInput";
import useReadOnly from "../shared/useReadOnly";
import FixedSubmitButton from "./components/FixedSubmitButton";
import HeaderProceso from "./components/HeaderProceso";

function allowWrite(permisos) {
    return permisos.admin || permisos['copiasArchivo'] === 'write'
}

const Input = AuthenticatedInput(allowWrite)

export default function CopiasArchivo({ mode }) {

    const { unidad, procesoId } = useParams();
    const readOnly = useReadOnly(allowWrite);
    const [copiasArchivo, setCopiasArchivo] = useState({
        notas: [],
        solicitudCopias: [],
        expedicionCopias: []
    })

    const [blockNavigation, setBlockNavigation] = useState(false)
    const [saving, saveCopiasArchivo,] = usePost(`proceso/${unidad}/copiasArchivo`)
    const [firmantes, setFirmantes] = useState([])

    useEffect(() => {

        async function fetchProceso() {
            const response = await Requester.get(`proceso/${unidad}/copiasArchivo/${procesoId}`);
            const data = response.data.copiasArchivo;
            if (data.notas) {
                data.notas = data.notas.map(n => {
                    n.fecha = new Date(n.fecha)
                    return n;
                })
            }
            setCopiasArchivo({ ...copiasArchivo, ...data })
        }

        async function fetchFirmantes() {
            const response = await Requester.get(`proceso/${unidad}/${procesoId}/firmantes`);
            setFirmantes([{ _id: 'rpcc', nombre: 'RPCC' },
            { _id: 'autoridad', nombre: 'Autoridad judicial' }
                , ...response.data.firmantes])
        }

        fetchProceso()
        fetchFirmantes()


    }, [unidad, mode, procesoId])


    function handleChange(name) {
        return function (e) {
            setBlockNavigation(true)
            setCopiasArchivo({ ...copiasArchivo, [name]: e })
        }
    }

    function handleClickAgregar(e) {
        setBlockNavigation(true)
        setCopiasArchivo({
            ...copiasArchivo,
            solicitudCopias: [...copiasArchivo.solicitudCopias, {
                solicitante: e.nombre,
                tipo: 'RPP',
                cantidad: 1,
                fecha: new Date()
            }],
            expedicionCopias: [...copiasArchivo.expedicionCopias, {
                expedida: false,
                libroRegistro: false
            }]
        })
    }

    function handleChangeSolicitudCopias(i, name) {
        return function (e) {
            setBlockNavigation(true)
            setCopiasArchivo({
                ...copiasArchivo,
                solicitudCopias: copiasArchivo.solicitudCopias.map((s, mi) => mi === i ? { ...s, [name]: e.target.value } : s)
            })
        }
    }

    function handleChangeExpedicionCopias(i, name) {
        return function (e) {
            setBlockNavigation(true)
            let transform = (s) => s;
            switch (name) {
                case 'expedida': {
                    const fechaExpedicion = e.target.checked ? new Date() : null
                    transform = (s) => ({ ...s, [name]: e.target.checked, fechaExpedicion })
                    break
                }
                case 'fechaExpedicion': {
                    const fechaExpedicion = e ? e : new Date()
                    transform = (s) => ({ ...s, [name]: e, fechaExpedicion })
                    break
                }
                case 'fechaEntrega': {
                    const fechaEntrega = e ? e : new Date()
                    transform = (s) => ({ ...s, [name]: e, fechaEntrega })
                    break
                }
                case 'libroRegistro': {
                    const fechaIntegracion = e.target.checked ? new Date() : null
                    transform = (s) => ({ ...s, [name]: e.target.checked, fechaIntegracion })
                    break;
                }
                case 'acuse': {
                    transform = (s) => ({ ...s, [name]: e, fechaEntrega: new Date() })
                    break;
                }
                default: {
                    transform = (s) => ({ ...s, [name]: e.target.value });
                }
            }

            setCopiasArchivo({
                ...copiasArchivo,
                expedicionCopias: copiasArchivo.expedicionCopias.map((s, mi) => mi === i ? transform(s) : s)
            })
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        await saveCopiasArchivo(copiasArchivo)
        setBlockNavigation(false)
        Swal.fire({
            icon: 'success',
            title: 'Guardado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        })
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Prompt
                when={blockNavigation}
                message={location => `¿Salir sin guardar cambios?`}
            ></Prompt>
            <HeaderProceso unidad={unidad} procesoId={procesoId}></HeaderProceso>
            <Header>
                Archivo y Copias
            </Header>
            <FormGroup className='GreyTop'>
                <Container>
                    <Subtitle>Archivo</Subtitle>
                    <br />
                    <br />
                    <Row>
                        <Col xs="4">
                            <Label>Convenio escaneado:</Label>
                        </Col>
                        <Col>
                            <FormattedDatePicker selected={copiasArchivo.convenioEscaneadoFecha}
                                onChange={handleChange('convenioEscaneadoFecha')}
                                readOnly={readOnly}
                                dateFormat="dd/MM/yyyy" />
                        </Col>
                        <Col xs="2">{copiasArchivo.convenioEscaneadoAgente ? copiasArchivo.convenioEscaneadoAgente.nombre : ''}</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="4">
                            <Label>Convenio archivado:</Label>
                        </Col>
                        <Col>
                            <FormattedDatePicker selected={copiasArchivo.convenioArchivadoFecha}
                                onChange={handleChange('convenioArchivadoFecha')}
                                readOnly={readOnly}
                                dateFormat="dd/MM/yyyy" />
                        </Col>
                        <Col xs="2">{copiasArchivo.convenioArchivadoAgente ? copiasArchivo.convenioArchivadoAgente.nombre : ''}</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="4">
                            <Label>Entrega definitiva al CJA:</Label>
                        </Col>
                        <Col>
                            <FormattedDatePicker selected={copiasArchivo.entregaDefinitivaCJAFecha}
                                onChange={handleChange('entregaDefinitivaCJAFecha')}
                                readOnly={readOnly}
                                dateFormat="dd/MM/yyyy" />
                        </Col>
                        <Col xs="2">
                            <FilePicker
                                route={`proceso/${unidad}/copiasArchivo/files/${copiasArchivo._id}`}
                                fileUrl={copiasArchivo.entregaDefinitivaCJAUrl}
                                readOnly={readOnly}
                                onChange={handleChange('entregaDefinitivaCJA')}>Adjuntar</FilePicker>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Notas
                        unidad={unidad}
                        tipo="copiasArchivo" notasProceso={copiasArchivo.notas} onChange={handleChange('notas')}
                        readOnly={readOnly}></Notas>
                    <br />
                    <br />
                    <br />
                    <div className='GreyTop'>
                        <Subtitle>Copias</Subtitle>
                    </div>
                    <br />
                    <Row>
                        <Col><p style={{ fontWeight: 'bold' }}>Expedir copia para</p></Col>
                        <SelectOne values={firmantes} valueLabel="nombre"
                            onSelect={handleClickAgregar}
                            readOnly={readOnly}></SelectOne>
                    </Row>
                    <br />
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Solicitante</th>
                                    {/* <th>Copia para</th> */}
                                    <th>Cantidad</th>
                                    <th>Fecha</th>
                                    <th>Agente</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    copiasArchivo.solicitudCopias.map((s, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{s.solicitante}</td>
                                            {/* <td>
                                                <Input type="select" className="FormInput in-table"
                                                    value={s.tipo}
                                                    onChange={handleChangeSolicitudCopias(i, "tipo")}
                                                    required>
                                                    <option value="RPP">RPP</option>
                                                    <option value="Para usuario">Para usuario</option>
                                                </Input>
                                            </td> */}
                                            <td>
                                                <Input type="number" className="FormInput in-table"
                                                    value={s.cantidad}
                                                    onChange={handleChangeSolicitudCopias(i, 'cantidad')}
                                                    required></Input>
                                            </td>
                                            <td>
                                                {s.fecha ? moment(s.fecha).format('DD-MM-YYYY') : ''}
                                            </td>
                                            <td>
                                                {s.agente?.nombre}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>

                    </Row>
                    <br />
                    <Row>
                        <Col><p style={{ fontWeight: 'bold' }}>Estatus de la solicitud de copias</p></Col>
                    </Row>
                    <br />
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Expedida</th>
                                    <th>Fecha expedición</th>
                                    <th>Fecha entrega</th>
                                    <th>Acuse</th>
                                    <th>Agente</th>
                                    <th>Razón integrada al Libro</th>
                                    <th>Fecha integración</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    copiasArchivo.expedicionCopias.map((e, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>
                                                <Input type="checkbox"
                                                    checked={e.expedida}
                                                    onChange={handleChangeExpedicionCopias(i, 'expedida')}></Input>
                                            </td>
                                            <td>
                                                {e.expedida &&
                                                    <FormattedDatePicker selected={e.fechaExpedicion}
                                                        onChange={handleChangeExpedicionCopias(i, 'fechaExpedicion')}
                                                        readOnly={readOnly}></FormattedDatePicker>
                                                }
                                            </td>
                                            <td>
                                                {e.expedida &&
                                                    <FormattedDatePicker selected={e.fechaEntrega}
                                                        onChange={handleChangeExpedicionCopias(i, 'fechaEntrega')}
                                                        readOnly={readOnly}></FormattedDatePicker>
                                                }
                                            </td>
                                            <td>
                                                {e.expedida &&
                                                    <FilePicker
                                                        route={`proceso/${unidad}/copiasArchivo/files/${copiasArchivo._id}`}
                                                        fileUrl={e.acuseUrl}
                                                        onChange={handleChangeExpedicionCopias(i, 'acuse')}
                                                        readOnly={readOnly}
                                                    >Adjuntar</FilePicker>
                                                }
                                            </td>
                                            <td>{e.agente?.nombre}</td>
                                            <td>
                                                {e.expedida &&
                                                    <Input type="checkbox"
                                                        checked={e.libroRegistro}
                                                        onChange={handleChangeExpedicionCopias(i, 'libroRegistro')}
                                                        readOnly={readOnly}></Input>
                                                }
                                            </td>
                                            <td>
                                                {e.expedida &&
                                                    moment(e.fechaIntegracion).format("DD-MM-YYYY")
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                    {copiasArchivo.expedicionCopias && copiasArchivo.expedicionCopias.length &&
                        <Row>
                            <Col>
                                <p style={{ color: 'red', fontStyle: 'italic' }}>
                                    Nota: Si el folio se encuentra en un libro que ya fue impreso, no se podrá integrar
                                    automáticamente la razón, debes de hacerlo
                                    manualmente.
                                </p>
                            </Col>
                        </Row>
                    }
                    <br />
                    <br />
                    <br />
                    <FixedSubmitButton saving={saving} readOnly={readOnly}></FixedSubmitButton>
                </Container>
            </FormGroup>
        </Form>
    )
}