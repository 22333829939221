import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import Header from "../shared/Header";
import Requester from "../shared/Requester";
import usePost from "../shared/usePost";
import Swal from "sweetalert2";
import IconButtonCustom from "../shared/IconButtonCustom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import IconButtonDelete from "../shared/IconButtonDelete";

const attrValues = {
    checkbox: 'checked',
    text: 'value'
}

const Lista = [
    {name: "Folio", value: "Folio", type: "nunmber"},
    {name: "Estatus", value: "Estatus", type: "text"},
    {name: "Asunto", value: "Asunto", type: "text"},
    {name: "Número Ticket", value: "Número Ticket", type: "number"},
    {name: "Facturado", value: "Facturado", type: "boolean"},
    {name: "Pagado", value: "Pagado", type: "boolean"},
    {name: "Cliente", value: "Cliente", type: "text"},
    {name: "Fecha Folio", value: "Fecha Folio", type: "date"},
    // {name: "Salida con Incidencia", value: "Salida con Incidencia", type: "date"},
    {name: "Registro público de la propiedad", value: "Registro público de la propiedad", type: "boolean"},
    {name: "Certificado de libertad de gravamenes", value: "Certificado de libertad de gravamenes"},
    {name: "Actos previos, fecha de ingreso", value: "Actos previos, fecha de ingreso"},
    {name: "Actos previos, fecha de egreso", value: "Actos previos, fecha de egreso"},
    {name: "Actos post-firma, Ingreso CJA", value: "Actos post-firma, Ingreso CJA"},
    {name: "Actos post-firma, Cita CJA", value: "Actos post-firma, Cita CJA"},
    {name: "Actos post-firma, Salida con incidencia", value: "Actos post-firma, Salida con incidencia"},
    {name: "Actos post-firma, Fecha inscripción", value: "Actos post-firma, Fecha inscripción"},
    {name: "Actos post-firma, Número registro", value: "Actos post-firma, Número registro"},
    {name: "Actos post-firma, Entidad", value: "Actos post-firma, Entidad"},
    {name: 'Actos post-firma, Ingreso Aviso de Cumplimiento', value: 'Actos post-firma, Ingreso Aviso de Cumplimiento'}, 
    {name: 'Actos post-firma, Salida Aviso de Cumplimiento', value: 'Actos post-firma, Salida Aviso de Cumplimiento'}, 
    {name: 'Actos post-firma, Ingreso convenio RPP', value: 'Actos post-firma, Ingreso convenio RPP'}, 
    {name: 'Actos post-firma, Estatus', value: 'Actos post-firma, Estatus'}, 
    {name: 'Actos post-firma, Salida Definitiva RPP', value: 'Actos post-firma, Salida Definitiva RPP'}, 
    {name: 'Entrega, Acuse firmado', value: 'Entrega, Acuse firmado'}, 
    {name: 'Archivo, Convenio escaneado', value: 'Archivo, Convenio escaneado'}, 
    {name: 'Archivo, Convenio archivado', value: 'Archivo, Convenio archivado'}, 
    {name: 'Archivo, Entrega definitiva CJA', value: 'Archivo, Entrega definitiva CJA'},
    {name: 'Firma, Nombre', value: 'Firma, Nombre'},
    {name: 'Firma, Tipo firma', value: 'Firma, Tipo firma'},
    {name: 'Firma, Fecha', value: 'Firma, Fecha'},
    {name: 'Firma, Firmado', value: 'Firma, Firmado'},
    {name:'Cita máxima CJA', value: 'Cita máxima CJA'},
    {name:'Término para presentar incidencia', value: 'Término para presentar incidencia'},
    {name:'Aviso de cumplimiento Término', value: 'Aviso de cumplimiento Término'},
    {name:'Convenio al RPP Término', value: 'Convenio al RPP Término'},
]

const FormaReporte = () => {
    const history = useHistory()
    const [propiedadesList, setPropiedadesList] = useState(Lista)
    const [camposList, setCamposList] = useState(Lista)
    const [filterList, setFilterList] = useState(["=",">","<","!=", 'exists'])
    const [prope, setPrope] = useState("");
    const [filter, setFilter] = useState({
        campo: "",
        comparacion: "",
        valor: ""
    });

    const { reporteId, unidad } = useParams()
    const [reporte, setReporte] = useState({
        nombre: "",
        unidad,
        propiedades: [],
        entidad: "Procesos",
        filtro: []
    });

    const [saving, saveReporte, ] = usePost('reportes')

    async function fetchLibro(reporteId) {
        const response = await Requester.get(`reportes/${reporteId}`)
        setReporte(response.data)
    }

    useEffect(() => {
        if (reporteId !== undefined)
            fetchLibro(reporteId)
    }, [reporteId])

    function handleChange(name) {
        return function (e) {
            setReporte({ ...reporte, [name]: e.target[attrValues[e.target.type]] })
        }
    }

    function handleChange(name) {
        return function (e) {
            switch (name) {
                case 'fechaCreacion': {
                    setReporte({ ...reporte, [name]: moment(e).format("MM/DD/YYYY") })
                    break;
                }
                default: {
                    setReporte({ ...reporte, [name]: e.target[attrValues[e.target.type]] })
                }
            }
        }
    }

    function handleAddFilter() {
        setReporte({...reporte, filtro: [...reporte.filtro, {valor: filter.valor === "" ? "null" : filter.valor, campo: filter.campo, comparacion: filter.comparacion}] })
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        await Requester.post(`reportes/${reporte.unidad}`, reporte);

        Swal.fire({
            icon: 'success',
            title: 'Guardado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        }).then(() => history.goBack());
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header>{reporteId === undefined ? "Nuevo" : "Editar"} reporte {reporteId === undefined ? "" : reporte.nombre}</Header>
            <FormGroup>
                <Container>
                    <Row>
                        <Col xs="3">
                            <Label for="nombre">Nombre del reporte *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="nombre" className="FormInput"
                                value={reporte.nombre}
                                onChange={handleChange("nombre")}
                                required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="entidad">Entidad *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="entidad" className="FormInput"
                                value={reporte.entidad}
                                onChange={handleChange("entidad")}
                                readOnly={true}
                                required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="fechaCreacion">Propiedades *</Label>
                        </Col>
                        <Col xs="3">
                            <Input type="select" className="FormInput in-table"
                                value={prope}
                                onChange={e => {setPrope(e.target.value)}}
                                >
                                <option value="">Seleccionar ...</option>
                                {propiedadesList.map((pl, i) => {
                                    return (
                                        <option key={i} value={pl.value}>{pl.name}</option>
                                    )
                                })}
                            </Input>
                            {/* <FormattedDatePicker value={moment(reporte.fechaCreacion).format("DD/MM/YYYY")}
                            onChange={handleChange('fechaCreacion')}></FormattedDatePicker> */}
                        </Col>
                        <Col xs="2">
                            <IconButtonCustom onClick={() => {
                                    if(prope !== '') {
                                    setReporte({...reporte, propiedades: [...reporte.propiedades, prope]});
                                    setPropiedadesList(propiedadesList.filter(p => p.value !== prope))
                                    }
                                }}
                                faIcon={faPlus}
                                color="black"
                                tooltip="Agregar a la lista"
                            />
                        </Col>
                        <Col xs="4">
                            <Table>
                                <thead>
                                    <th>Propiedades</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    {reporte.propiedades.map((p, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{p}</td>
                                                <td>
                                                    <IconButtonDelete 
                                                        onClick={(i) => {
                                                            setReporte({...reporte, propiedades: reporte.propiedades.filter(r => r !== p)});
                                                            setPropiedadesList([...propiedadesList, Lista.find(f => f.value === p)])
                                                            }
                                                        }/>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="filtro">Filtro *</Label>
                        </Col>
                        <Col xs="3">
                            <Input type="select" className="FormInput in-table"
                                value={filter.campo}
                                onChange={e => {setFilter({...filter, campo: e.target.value})}}
                                >
                                <option value="">Seleccionar ...</option>
                                {camposList.map((pl, i) => {
                                    return (
                                        <option key={i} value={pl.value}>{pl.name}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col xs="2">
                            <Input type="select" className="FormInput in-table"
                                value={filter.comparacion}
                                onChange={e => {setFilter({...filter, comparacion: e.target.value})}}
                                >
                                <option value="">Seleccionar ...</option>
                                {filterList.map((f, i) => {
                                    return (
                                        <option key={i} value={f}>{f}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col xs="2" >
                            <Input type="text" id="value" className="value"
                                value={filter.valor}
                                onChange={e => setFilter({...filter, valor: e.target.value})}
                                >
                            </Input>
                        </Col>
                        <Col xs="2" >
                            <Button color="secondary" type="button" 
                                onClick={handleAddFilter}
                            >Agregar</Button>
                        </Col>
                        <br />
                        <br />
                        <Col>
                            <Row>
                                <Table>
                                    <thead>
                                        <th>Campo</th>
                                        <th>Comparación</th>
                                        <th>Valor</th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        {reporte.filtro.map((f, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{f.campo}</td>
                                                    <td>{f.comparacion}</td>
                                                    <td>{f.valor}</td>
                                                    <td>
                                                        <IconButtonDelete 
                                                            onClick={(i) => setReporte({...reporte, filtro: reporte.filtro.filter(r => r !== f)})}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                                </Row>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col xs="10"></Col>
                        <Col xs="2">
                            <Button color="secondary" type="submit">Guardar</Button>
                        </Col>
                    </Row>
                    
                </Container>
            </FormGroup>
        </Form>
    );
};

export default FormaReporte;