import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Header from '../shared/Header';
import Requester from '../shared/Requester'
import { useParams } from 'react-router-dom';
import ArmadoPreview from './ArmadoPreview';
import ReactPaginate from 'react-paginate';

export default function ArmadosList({ }) {

    const { unidad } = useParams()
    const [armados, setArmados] = useState([])
    const [armadosNumber, setArmadosNumber] = useState(0);
    const limit = 16;

    useEffect(() => {
        if (unidad) {
            async function countArmados() {
                const response = await Requester.get(`armados/${unidad}/count`)
                setArmadosNumber(response.data)
            }
            countArmados()
        }
    }, [unidad])

    useEffect(() => {
        if (unidad && armadosNumber > 0) {
            fetchArmados()
        }
    }, [unidad, armadosNumber])

    async function fetchArmados(skip = 0) {
        const response = await Requester.get(`armados/${unidad}?skip=${skip}&limit=${limit}`)
        setArmados(response.data)
    }

    function handleSent(a) {
        return function () {
            setArmados(armados.map(armado => {
                if (armado._id === a._id) {
                    return {
                        ...armado,
                        estatus: 'Enviado'
                    }
                }
                return armado
            }
            ));
        }
    }

    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        fetchArmados(itemOffset)
        setPageCount(Math.ceil(armadosNumber / limit));
    }, [itemOffset, limit, armadosNumber]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * limit) % armadosNumber;
        setItemOffset(newOffset);
    };


    return (
        <Container>
            <Header>Armados</Header>
            <ReactPaginate
                className='paginate'
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
            />
            <Row>
                {
                    armados.map(a =>
                        <Col xs={12} md={3}>
                            <ArmadoPreview key={a._id} armado={a} onSent={handleSent(a)} />
                        </Col>)
                }
            </Row>
        </Container>
    )

}