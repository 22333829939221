import React from "react";
import { useParams } from "react-router-dom";
import usePost from "../shared/usePost";
import SelectAgenteFormDialog from "../shared/SelectAgenteFormDialog";


export default function ArmadoEnvioFormDialog({armado, onSave, disabled = false, reSend}) {
    
    const {unidad} = useParams()
    const [working, sendArmado,] = usePost(`armados/${unidad}/${armado._id}/enviar`)    

    async function handleConfirm(agenteSeleccionado) {
        await sendArmado({
            agenteId: agenteSeleccionado
        })
        onSave({})
    }

    return (
        <SelectAgenteFormDialog
            title="Enviar armado a agente"
            submitLabel="Enviar"
            body={
                <p>
                    Selecciona el agente al que quieres enviar este proceso de armado. 
                    {
                        armado.estatus === 'Pendiente' && <b>Una vez enviado, se cerrará este armado</b>
                    }

                </p>
                }
            onConfirm={handleConfirm}
            buttonLabel={armado.estatus === 'Pendiente' ? 'Enviar' : 'Reenviar'}
            disabled={working || disabled}
                >

        </SelectAgenteFormDialog>
    )

}