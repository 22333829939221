import {useState} from 'react';
import Requester from './Requester'

export default function usePost(path) {
    const [working, setWorking] = useState(false);
    const [response, setResponse] = useState(null)

    async function doPost(body, multipart) {
        setWorking(true);
        let result = null;
        try {
            if(multipart) {
                result = await Requester.postMultipart(path, body)
            } else {
                result = await Requester.post(path, body)
            }        
            setResponse(result);
        } catch(e) {

        }
        setWorking(false);
        return result;
    }

    return [working, doPost, response];
    
}