import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'


function IconButtonDelete({onClick}) {
    return (
        <div onClick={onClick}>
            <FontAwesomeIcon icon={faTrash} style={{color:'red', cursor:'pointer'}}></FontAwesomeIcon>
        </div>
    )
}

export default IconButtonDelete