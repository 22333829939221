import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

function IconButtonCustom({ onClick, faIcon, color, tooltip, id, style, allowAccess = true }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [uuid,] = useState("id-"+Math.floor(Math.random()*10000).toString())
    const [uuiddos,] = useState("i-"+Math.floor(Math.random()*10000).toString())

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        allowAccess ?
        <div id={uuiddos} onClick={onClick} className={style}>
            <FontAwesomeIcon id={uuid} icon={faIcon} style={{ color, cursor: 'pointer' }}></FontAwesomeIcon>
            <Tooltip id={id} placement="right" isOpen={tooltipOpen} target={uuid} toggle={toggle}>
                {tooltip}
            </Tooltip>
        </div> : null
    )
}

export default IconButtonCustom