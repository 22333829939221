import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import FormDialog from "../shared/FormDialog";
import usePost from "../shared/usePost";


export default function AbrirSeguimientoFormDialog({onSave}) {

    const {unidad} = useParams();
    const [tipoSeguimiento, setTipoSeguimiento] = useState('En tiempo');
    const [working, abrirSeguimiento] = usePost(`seguimientos/${unidad}`);

    function handleChange(e) {
        setTipoSeguimiento(e.target.value)
    }

    async function handleConfirm(data) {
        const response = await abrirSeguimiento({
            tipo: data
        })
        onSave(response.data)
    }

    return (
        <FormDialog
            title="Abrir seguimiento"
            data={tipoSeguimiento}
            submitLabel="Abrir"
            body={
                <Container>
                    <Row>
                        <Col xs="3">
                            <Label for="tipoSeguimiento">Tipo de seguimiento</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="select" 
                                name="tipoSeguimiento" 
                                id="tipoSeguimiento" 
                                value={tipoSeguimiento}
                                disabled={working}
                                onChange={handleChange}>
                                    <option value="En tiempo">En tiempo</option>
                                    <option value="Extemporáneo">Extemporáneo</option>
                                    <option value="Subsanado">Subsanado</option>
                            </Input>
                        </Col>
                    </Row>
                </Container>
            }>
            {
                confirm => (
                    <Button color="secondary" style={{ margin: '20px 0' }} onClick={confirm(handleConfirm)} disabled={working}>
                        Abrir seguimiento
                    </Button>
                )
            }
        </FormDialog>

    )

}