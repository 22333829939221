import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import FormattedDatePicker from "../shared/FormattedDatePicker";
import Header from "../shared/Header";
import Requester from "../shared/Requester";
import usePost from "../shared/usePost";
import Swal from "sweetalert2";

const attrValues = {
    checkbox: 'checked',
    text: 'value'
}

const FormaLibro = () => {
    const history = useHistory()
    const { libroId, unidad } = useParams()
    const [libro, setLibro] = useState({
        numero: "",
        fechaImpresion: "",
        unidad
    });

    const [saving, saveLibro, ] = usePost('libros')

    async function fetchLibro(libroId) {
        const response = await Requester.get(`libros/${libroId}`)
        setLibro(response.data)
    }

    useEffect(() => {
        if (libroId !== undefined)
            fetchLibro(libroId)
    }, [libroId])

    function handleChange(name) {
        return function (e) {
            setLibro({ ...libro, [name]: e.target[attrValues[e.target.type]] })
        }
    }

    function handleChange(name) {
        return function (e) {
            switch (name) {
                case 'fechaCreacion': {
                    setLibro({ ...libro, [name]: moment(e).format("MM/DD/YYYY") })
                    break;
                }
                default: {
                    setLibro({ ...libro, [name]: e.target[attrValues[e.target.type]] })
                }
            }
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        await Requester.post(`libros/${libro.unidad}`, libro);

        Swal.fire({
            icon: 'success',
            title: 'Guardado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        }).then(() => history.goBack());
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header>{libroId === undefined ? "Nuevo" : "Editar"} libro {libroId === undefined ? "" : libro.numero}</Header>
            <FormGroup>
                <Container>
                    <Row>
                        <Col xs="3">
                            <Label for="folioInicial">Folio inicial *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="folioInicial" className="FormInput"
                                value={libro.folioInicial}
                                onChange={handleChange("folioInicial")}
                                required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="folioFinal">Folio final *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="folioFinal" className="FormInput"
                                value={libro.folioFinal}
                                onChange={handleChange("folioFinal")}
                                required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="fechaCreacion">Fecha creación *</Label>
                        </Col>
                        <Col xs="auto">
                            {/* <Input type="date" id="fechaCreacion" className="FormInput"
                                value={FormattedDate(libro.fechaCreacion)}
                                onChange={handleChange("fechaCreacion")}
                                required></Input> */}
                                <FormattedDatePicker value={moment(libro.fechaCreacion).format("DD/MM/YYYY")}
                                onChange={handleChange('fechaCreacion')}></FormattedDatePicker>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col xs="10"></Col>
                        <Col xs="2">
                            <Button color="secondary" type="submit">Guardar</Button>
                        </Col>
                    </Row>
                </Container>
            </FormGroup>
        </Form>
    );
};

export default FormaLibro;