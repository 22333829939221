import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { Container, Input, Row, Table, Button, Col } from "reactstrap";
import Swal from "sweetalert2";
import SelectOne from "../proceso/components/SelectOne";
import ConfirmDialog from "../shared/ConfirmDialog";
import FilePicker from "../shared/FilePicker";
import FormattedDate from "../shared/FormattedDate";
import FormattedDatePicker from "../shared/FormattedDatePicker";
import Header from "../shared/Header";
import Requester from "../shared/Requester";
import usePost from "../shared/usePost";
import FormaSeguimientoFolio from "./FormaSeguimientoFolio";
import InformarCJAFormDialog from "./InformarCJAFormDialog";

export default function FormaSeguimiento() {

    const {unidad, seguimientoId} = useParams();
    const [seguimiento, setSeguimiento] = useState({});
    const [foliosDisponibles, setFoliosDisponibles] = useState([])
    const [agregandoFolio, agregarFolio] = usePost(`seguimientos/${unidad}/${seguimientoId}/folio`)
    const [actualizando, actualizarSeguimiento] = usePost(`seguimientos/${unidad}/${seguimientoId}`)
    const [fechaMaximaCJA, setFechaMaximaCJA] = useState(null);
    const [working, setWorking] = useState(false)

    useEffect(() => {

        async function fetchSeguimiento() {
            const response = await Requester.get(`seguimientos/${unidad}/${seguimientoId}`);
            const s = response.data;
            s.folios = s.folios.map(f => ({
                ...f,
                bloquear: f.rechazado && f.aplicado
            }));
            setSeguimiento(response.data);
        }

        async function fetchFechaMaximaCJA() {
            const response = await Requester.get(`seguimientos/${unidad}/${seguimientoId}/fechaMaximaCJA`);
            if(response.data?.length) {
                setFechaMaximaCJA(response.data[0].citaCJAFechaMaxima);
            }            
        }

        if(unidad, seguimientoId && !agregandoFolio && !actualizando) {
            fetchSeguimiento();
            fetchFechaMaximaCJA();
        }

    }, [unidad, seguimientoId, agregandoFolio, actualizando])

    useEffect(() => {

        async function fetchFoliosDisponibles() {
            const response = await Requester.get(`seguimientos/${unidad}/folios`);
            setFoliosDisponibles(
                response.data
                .filter(a => !seguimiento.folios.find(f => f.folio === a.procesos.solicitudFolio.folio))
                .map(a => ({
                _id: a.procesos.solicitudFolio.folio,
                folio: a.procesos.solicitudFolio.folio
            })));
        }

        if(seguimiento?.folios?.length < 10) {
            fetchFoliosDisponibles();
        }

    }, [seguimiento])

    useEffect(() => {

        setWorking(agregandoFolio || actualizando)

    }, [agregandoFolio, actualizando])

    async function handleClickAgregar(folio) {
        await agregarFolio({
            folio: folio.folio
        })
    }

    function handleChangeFolio(folioId) {
        return function(tipo) {
            return function(e) {
                setSeguimiento({
                    ...seguimiento,
                    folios: seguimiento.folios.map(folio => {
                        if(folio._id === folioId) {
                            return {
                                ...folio,
                                [tipo]: e.target?.value || e,
                                aplicado: false
                            }
                        }
                        return folio;
                    })
                })
            }
        }
    }

    function handleChangeSeguimiento(nombre) {
        return function(e) {

            if(nombre === 'citaSolicitada') {
                setSeguimiento({
                    ...seguimiento,
                    citaSolicitada: !seguimiento.citaSolicitada
                })
                return;
            }

            if(nombre === 'ingresoCJA.fecha') {
                setSeguimiento({
                    ...seguimiento,
                    ingresoCJA: {
                        fecha: e
                    }
                })
                return;
            }

            setSeguimiento({
                ...seguimiento,
                [nombre]: e.target?.value || e
            })
        }
    }

    async function handleSave() {
        await actualizarSeguimiento(seguimiento);
        Swal.fire({
            icon: 'success',
            title: 'Guardado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        })
    }

    async function handleOnAplicarFolio(folio) {
        await actualizarSeguimiento({
            ...seguimiento,
            folios: seguimiento.folios.map(a => {
                if(a._id === folio._id) {
                    return {
                        ...a,
                        aplicado: true
                    }
                }
                return a;
            })
        });
    }
    
    async function handleOnEliminarFolio(folio) {
        setSeguimiento({
            ...seguimiento,
            folios: seguimiento.folios.filter(a => a._id !== folio._id)
        })
    }

    async function handleClickSolicitarFondeo() {
        await Requester.post(`seguimientos/${unidad}/${seguimientoId}/notificaciones/fondeo`, {

        });
        Swal.fire({
            icon: 'success',
            title: 'Enviado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        })
    }

    async function handleSolicitarCitaEnviado() {
        Swal.fire({
            icon: 'success',
            title: 'Enviado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        })
    }


    return (
        <div>
            <Header>Seguimiento {seguimiento.numero}</Header>
            <Container>
                <Row>
                    <Col>
                        <div>Tipo de seguimiento:</div>
                    </Col>
                    <Col>
                        {seguimiento.tipo}
                    </Col>
                    <Col xs="6"></Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <div>Cita Solicitada:</div>
                    </Col>
                    <Col>
                        <Input type="checkbox" checked={seguimiento.citaSolicitada} onChange={handleChangeSeguimiento('citaSolicitada')} />
                    </Col>
                    <Col xs="6"></Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <div>Cita CJA:</div>
                    </Col>
                    <Col>
                        <FormattedDatePicker selected={seguimiento.citaCJA} onChange={handleChangeSeguimiento('citaCJA')} />
                    </Col>
                    <Col xs="6"></Col>
                </Row>
                <br />
                <Row>
                    <b>Folios</b>
                </Row>
                <br />
                <Row>
                    <SelectOne values={foliosDisponibles} valueLabel="folio"
                        onSelect={handleClickAgregar}
                        readOnly={working || seguimiento.fondeado?.archivoUrl}/>
                </Row>
                <br />
                <br />
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Ticket</th>
                                <th>Folio convenio</th>
                                <th>Páginas</th>
                                <th>Num. Anexos</th>
                                <th>Fecha de firma</th>
                                <th>Inscripción CJA</th>
                                <th>Número de registro</th>
                                <th>Rechazado</th>
                                <th>Elaborado</th>
                                <th>Agente</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {seguimiento?.folios?.map((folio, i) => (
                                <FormaSeguimientoFolio key={folio._id} 
                                    index={i} 
                                    folio={folio}
                                    unidad={unidad}
                                    seguimientoId={seguimientoId}
                                    handleChangeFolio={handleChangeFolio(folio._id)}
                                    onAplicarFolio={handleOnAplicarFolio}
                                    onEliminarFolio={handleOnEliminarFolio}></FormaSeguimientoFolio>
                            ))}
                        </tbody>
                    </Table>                    
                </Row>
                <br />
                <br />
                <Row>
                    <Col><div>Fecha máxima para pedir cita CJA:</div> </Col>
                    <Col>{FormattedDate(fechaMaximaCJA)}</Col>
                    <Col xs="6"></Col>
                </Row>
                <br />
                <Row>
                    <Col><div>Derechos por pagar</div></Col>
                    <Col><Input type="number" value={seguimiento.derechosPorPagar} onChange={handleChangeSeguimiento('derechosPorPagar')} readOnly={working}/></Col>
                    <Col>
                        {
                            seguimiento.derechosPorPagar > 0 ?
                            <ConfirmDialog
                                title="Solicitar fondeo"
                                body="¿Desea solicitar fondeo?">
                                    {
                                        confirm => (
                                            <Button onClick={confirm(handleClickSolicitarFondeo)}>Solicitar fondeo</Button>
                                        )
                                    }
                            </ConfirmDialog> : null
                        }
                    </Col>
                    <Col xs="6"></Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Elaborado</th>
                                <th>Agente</th> 
                                <th></th>
                            </tr>                           
                        </thead>
                        <tbody>
                            <tr>
                                <td>Fondeado</td>
                                <td>
                                    <FilePicker
                                        route={`seguimientos/files/${seguimiento._id}`}
                                        fileUrl={seguimiento.fondeado?.archivoUrl}
                                        onChange={handleChangeSeguimiento('fondeado')}>Adjuntar</FilePicker>
                                </td>
                                <td>
                                    {FormattedDate(seguimiento?.fondeado?.archivoFecha)}                                    
                                </td>
                                <td>
                                    {seguimiento?.fondeado?.agente?.nombre}
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Pagado CJA</td>
                                <td>
                                    <FilePicker
                                        disabled={!seguimiento.fondeado?.archivoUrl}
                                        route={`seguimientos/files/${seguimiento._id}`}
                                        fileUrl={seguimiento.pagadoCJA?.archivoUrl}
                                        onChange={handleChangeSeguimiento('pagadoCJA')}>Adjuntar</FilePicker>
                                </td>
                                <td>
                                    {FormattedDate(seguimiento?.pagadoCJA?.archivoFecha)}                                    
                                </td>
                                <td>
                                    {seguimiento?.pagadoCJA?.agente?.nombre}
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Formato Cita CJA</td>
                                <td>
                                    <FilePicker
                                        disabled={!seguimiento.pagadoCJA?.archivoUrl}
                                        route={`seguimientos/files/${seguimiento._id}`}
                                        fileUrl={seguimiento.formatoCitaCJA?.archivoUrl}
                                        onChange={handleChangeSeguimiento('formatoCitaCJA')}>Adjuntar</FilePicker>
                                </td>
                                <td>
                                    {FormattedDate(seguimiento?.formatoCitaCJA?.archivoFecha)}
                                </td>
                                <td>
                                    {seguimiento?.formatoCitaCJA?.agente?.nombre}                                    
                                </td>
                                <td>
                                    {
                                        seguimiento?.pagadoCJA?.archivoUrl &&
                                        seguimiento?.formatoCitaCJA?.archivoUrl ?
                                        <InformarCJAFormDialog
                                            disabled={working}
                                            seguimientoId={seguimientoId}
                                            onSave={handleSolicitarCitaEnviado}
                                        ></InformarCJAFormDialog> : null
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>Ingreso a CJA</td>
                                <td>
                                    <FormattedDatePicker selected={seguimiento.ingresoCJA?.fecha}
                                        disabled={!seguimiento.formatoCitaCJA?.archivoUrl}
                                        onChange={handleChangeSeguimiento('ingresoCJA.fecha')} 
                                        dateFormat="dd/MM/yyyy"
                                        readOnly={working}></FormattedDatePicker >
                                </td>
                                <td>
                                    {FormattedDate(seguimiento?.ingresoCJA?.fechaRegistro)}
                                </td>
                                <td>                                        
                                    {seguimiento?.ingresoCJA?.agente?.nombre}             
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
                <br/>
                <Row>
                    <Col xs="11"></Col>                        
                    <Col xs="1">                        
                        <Button color="secondary" 
                            style={{ margin: '20px 0' }}
                            onClick={handleSave}
                            disabled={working} >Guardar</Button>
                    </Col>
                </Row> 
            </Container>
        </div>
    )

}