import { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import Swal from "sweetalert2";
import ConfirmDialog from "../shared/ConfirmDialog";
import FormattedDatePicker from "../shared/FormattedDatePicker";
import IconButtonDelete from "../shared/IconButtonDelete";
import Requester from "../shared/Requester";
import usePost from "../shared/usePost";


export default function FormaSeguimientoFolio({ index, unidad, folio, handleChangeFolio, seguimientoId, onAplicarFolio, onEliminarFolio }) {

    const [fechaUltimaFirma, setFechaUltimaFirma] = useState();
    const [rechazadoTieneAdjunto, setRechazadoTieneAdjunto] = useState(false);
    const [procesoId, setProcesoId] = useState(null);
    const [aplicando, aplicarFolio] = usePost(`seguimientos/${unidad}/${seguimientoId}/folios/${folio.folio}/aplicar`)
    const [eliminando, eliminarFolio] = usePost(`seguimientos/${unidad}/${seguimientoId}/folios/${folio.folio}/eliminar`)

    const [working, setWorking] = useState(false);

    useEffect(() => {

        async function fetchUltimaFechaFirma() {
            const response = await Requester.get(`seguimientos/${unidad}/folios/${folio.folio}/fechaFirma`);
            if (response.data?.length) {
                setFechaUltimaFirma(response.data[0].ultimaFechaFirma);
            }
        }

        async function fetchRechazadoTieneAdjunto() {
            const response = await Requester.get(`seguimientos/${unidad}/folios/${folio.folio}/rechazadoAdjunto`)
            if(response.data) {
                setRechazadoTieneAdjunto(response.data.tieneAdjunto)
            }
        }

        async function fetchProcesoId() {
            const response = await Requester.get(`seguimientos/${unidad}/folios/proceso/${folio.ticket}`)
            if(response.data) {
                setProcesoId(response.data.procesoId)
            }
        }

        if (folio?.folio) {
            fetchUltimaFechaFirma();
            fetchRechazadoTieneAdjunto();
            fetchProcesoId()
        }

    }, [folio.folio])

    useEffect(() => {

        setWorking(aplicando || eliminando)

    }, [aplicando, eliminando])

    async function handleClickAplicar() {
        await aplicarFolio(folio)
        await onAplicarFolio(folio);        
        Swal.fire({
            icon: 'success',
            title: 'Aplicado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        })
    }

    async function handleClickEliminar() {
        await eliminarFolio(folio)
        await onEliminarFolio(folio);        
    }

    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                {
                procesoId ? 
                    <a href={`/${unidad}/proceso/infoPreliminar/${procesoId}`} target="_blank" rel="noreferrer">{folio.ticket}</a> : null
                }
            </td>
            {/* <td>{folio.ticket}</td> */}
            <td>{folio.folio}</td>
            <td>
                <Input type="number" value={folio.paginas}
                    onChange={handleChangeFolio('paginas')}
                    readOnly={working || folio.bloquear} />
            </td>
            <td>
                <Input type="number" value={folio.numAnexos}
                    onChange={handleChangeFolio('numAnexos')}
                    readOnly={working || folio.bloquear} />
            </td>
            <td>
                <FormattedDatePicker
                    wrapperClassName="tableDatePicker"
                    selected={fechaUltimaFirma}
                    readOnly={true} />
            </td>
            <td>
                <FormattedDatePicker selected={folio.inscripcionCJA}
                    wrapperClassName="tableDatePicker"
                    onChange={handleChangeFolio('inscripcionCJA')}
                    readOnly={working || folio.bloquear} />
            </td>
            <td>
                <Input type="text" value={folio.numeroRegistro}
                    onChange={handleChangeFolio('numeroRegistro')}
                    readOnly={working || folio.bloquear} />
            </td>
            <td>
                <FormattedDatePicker selected={folio.rechazado}
                    wrapperClassName="tableDatePicker"
                    onChange={handleChangeFolio('rechazado')}
                    readOnly={working || folio.bloquear} />
                {
                    !rechazadoTieneAdjunto &&
                    <div>
                        <b>Ve al ticket y adjunta el rechazo</b>
                    </div>
                }
            </td>
            <td>
                <FormattedDatePicker selected={folio.fechaRegistro}
                    readOnly={true}
                    wrapperClassName="tableDatePicker" />
            </td>
            <td>{folio.agente?.nombre}</td>
            <td>
                <ConfirmDialog
                    title="Pegar a ticket"
                    body={`Se pegarán los datos de este folio en el ticket`}>
                    {
                        confirm => (
                            <Button
                                disabled={folio.bloquear}
                                 onClick={confirm(handleClickAplicar)}>Aplicar</Button>
                        )
                    }
                </ConfirmDialog>                
            </td>
            <td>
                {
                    !folio.bloquear && 
                    <ConfirmDialog
                        title="Eliminar folio"
                        body={`Se eliminará este folio de este seguimiento`}>
                        {
                            confirm => (
                                <IconButtonDelete 
                                    onClick={confirm(handleClickEliminar)}></IconButtonDelete>
                            )
                        }
                    </ConfirmDialog>   
                }             
            </td>
        </tr>
    )

}