import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
import Clientes from './clientes/Clientes';
import Libros from './libros/Libros';
import Login from './Login';
import Proceso from './proceso/Proceso';
import Menu from './shared/menus/menuPrincipal/Menu';
import Tickets from './tickets/TicketsList';
import Unidades from './unidades/Unidades';
import Usuarios from './usuarios/Usuarios';
import Armados from './armados/Armados'
import Seguimientos from './seguimientos/Seguimientos';
import "react-datepicker/dist/react-datepicker.css";
import { Container, Col, Row } from "reactstrap";
import { SessionContext } from "./shared/session";
import SelectUnidad from "./shared/SelectUnidad";
import Requester from "./shared/Requester";
import { useEffect, useState } from "react";
import Reportes from "./reportes/Reportes";


function App() {

  const logedIn = localStorage.getItem('loggedIn');

  if (logedIn === null && window.location.pathname !== '/login') {
    window.location.href = '/login';
  }

  const [sessionData, setSessionData] = useState({
    unidad: null,
    permisos: null
  })

  useEffect(() => {
    async function fetchPermisos() {      
      const response = await Requester.get(`usuarios/permisos/${sessionData.unidad._id}`)      
      setSessionData({
        ...sessionData, permisos: response.data
      })
    }

    if(sessionData.unidad) {
      fetchPermisos()      
    }    
  }, [sessionData.unidad])
  

  function handleChangeUnidad(unidad) {
    setSessionData({
      unidad, permisos: null
    })
  }

  return (
    <Router>
      <div style={{ margin: '30px 100px' }}>
        <Route path="/login">
          <Login />
        </Route>
        <SessionContext.Provider value={sessionData}>
          <Container>
            <Col>
              <Row>
                <div style={{
                  width: '100vw', position: 'fixed', top: 0, left: 0,
                  height: '3vw', zIndex:10
                }}>
                  <SelectUnidad unidad={sessionData.unidad} onChange={handleChangeUnidad}></SelectUnidad>
              </div>
              </Row>
              <Row style={{ marginTop: '4vw' }}>
                <Route path="/:unidad/tickets">
                  <Menu />
                  <Tickets />
                </Route>
                <Route path="/:unidad/armados">
                  <Menu />
                  <Armados />
                </Route>
                <Route path="/:unidad/seguimientos">
                  <Menu />
                  <Seguimientos />
                </Route>
                <Route path="/:unidad/clientes">
                  <Menu />
                  <Clientes />
                </Route>
                <Route path="/:unidad/usuarios">
                  <Menu />
                  <Usuarios />
                </Route>
                <Route path="/:unidad/unidades">
                  <Menu />
                  <Unidades />
                </Route>
                <Route path="/:unidad/libros">
                  <Menu />
                  <Libros />
                </Route>
                <Route path="/:unidad/reportes">
                  <Menu />
                  <Reportes />
                </Route>
                <Route path="/:unidad/proceso">
                  <Menu />
                  <Proceso />
                </Route>
              </Row>
            </Col>
          </Container>
        </SessionContext.Provider>
      </div>
    </Router>
  );
}

export default App;
