import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import FormDialog from "../shared/FormDialog";
import Requester from "../shared/Requester";
import { useParams } from "react-router-dom";


export default function SelectAgenteFormDialog({onSave, disabled = false, title, data, submitLabel, body, onConfirm, buttonLabel}) {

    const {unidad} = useParams()
    const [agentes, setAgentes] = useState([])
    const [agenteSeleccionado, setAgenteSeleccionado] = useState(null)

    useEffect(() => {

        async function fetchAgentes() {
            const response = await Requester.get(`usuarios/unidad/${unidad}`)
            setAgentes(response.data)
            
            if(response.data.length > 0) {
                setAgenteSeleccionado(response.data[0]._id)
            }
        }

        if(unidad) {
            fetchAgentes()
        }

    }, [unidad])

    function handleChangeAgente(e) {
        setAgenteSeleccionado(e.target.value)
    }

    function handleConfirm(agente) {
        onConfirm(agente)
    }

    return (
        <FormDialog 
            title={title}
            data={agenteSeleccionado}
            submitLabel={submitLabel}
            body={
                <Container>
                    <Row>
                        {body}
                    </Row>
                    <Row>
                        <Col xs="3">
                            <Label for="nombre">Agente</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="select" id="agente" className="FormInput"
                                value={agenteSeleccionado}
                                onChange={handleChangeAgente}
                                disabled={disabled}>
                                {
                                    agentes.map(agente => (
                                        <option key={agente._id} value={agente._id}>{agente.nombre} - {agente.correo}</option>
                                    ))
                                }
                            </Input>
                        </Col>
                    </Row>
                </Container>
        }>
            {
                confirm => (
                    <Button color="secondary" disabled={disabled} style={{ margin: '20px 0' }} onClick={confirm(handleConfirm)}>
                        {buttonLabel}
                    </Button>
                )
            }
        </FormDialog>
    )

}