import { withRouter, useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Requester from "./Requester";
import { Col, Input, Label, Row } from "reactstrap";

function Logon(usuario) {
    return (
        usuario && usuario != "" ?
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Label for="unidad" style={{ width: '100%', textAlign: 'right', paddingRight: '10px', lineHeight: '40px' }}>Bienvenido, <b>{usuario.split("@")[0]}</b></Label>
            </div> : 
            <div>
                &nbsp;
            </div>
    )
}

export default withRouter(function SelectUnidad({ loggedIn, unidad, onChange }) {
    

    const [unidades, setUnidades] = useState([]);
    const [usuario, setUsuario] = useState('');

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        async function fetchUnidades() {
            if (localStorage.getItem('loggedIn')) {
                let usr = ""
                if (localStorage.getItem('psaAgent')) {
                    usr = localStorage.getItem('psaAgent');
                    setUsuario(usr);
                }
                const response = await Requester.get(encodeURI(`unidades/user/${usr}?onlyActive=true`));

                const selectFirst = !unidades || unidades.length === 0
                setUnidades(response.data)

                const splitted = location.pathname.split("/")
                const paramsUnidad = splitted.length > 1 ? splitted[1] : null
                // se traen las unidades cada vez que se cambia de path para asegurar que esten 
                // siempre actualizadas. Pero solo se selecciona la primera la primera vez
                if (onChange && selectFirst) {
                    const selected =  paramsUnidad ? response.data.find(u => u._id === paramsUnidad) : response.data[0]
                    onChange(selected)
                }

            }
        }

        fetchUnidades()

    }, [loggedIn, location.pathname])

    useEffect(() => {

        if (unidad) {
            const p = location.pathname.split('/')
            p[1] = unidad._id;
            if (p.length === 2) {
                p.push('tickets')
            }
            history.push(p.join('/'))
        }

    }, [unidad])


    function handleChangeUnidad(e) {
        onChange(unidades.find(u => u._id === e.target.value))
    }

    return (
        <div>
            {location.pathname !== '/login' && !location.pathname.includes('/proceso/') ?
                <Row style={{ background: 'white', padding: '5px', zIndex: 10 }}>
                    <Col xs='5'></Col>
                    <Col xs='5'>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Label for="unidad" style={{ width: '100%', textAlign: 'right', paddingRight: '10px', lineHeight: '40px' }}>Unidad:</Label>
                            <Input type="select" id="unidad" value={unidad?._id} onChange={handleChangeUnidad}>
                                {
                                    unidades.map(u => (
                                        <option key={u._id} value={u._id}>{u.nombre}</option>
                                    ))
                                }
                            </Input>
                        </div>
                    </Col>
                    <Col xs='2'>
                        {Logon(usuario)}
                    </Col>
                </Row> :
                <Row style={{ background: 'white', padding: '5px', zIndex: 10, lineHeight: '40px' }}>
                    <Col xs='6'></Col>
                    <Col xs='6'>
                        {Logon(usuario)}
                    </Col>
                </Row>}
        </div>
    )
})