import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import isotipo from '../../../assets/logo192.png'
import IconButtonCustom from '../../IconButtonCustom';
import { faUserTie, faTicketAlt, faUsersCog, faBook, faBuilding, faSignOutAlt, faFileAlt, faPuzzlePiece, faTruck } from '@fortawesome/free-solid-svg-icons'
import Requester from '../../Requester';
import './Menu.css'
import { SessionContext } from "../../session";

const style = "mt-4 mb-4"

function allowAccess(permisos, modulo) {
    return permisos?.admin || permisos?.[modulo] === 'read' || permisos?.[modulo] === 'write';
}

const Menu = ({ isoTipo = null }) => {
    const history = useHistory();
    const [isClosed, setIsClosed] = useState(true);
    const {unidad} = useParams()
    const {permisos} = useContext(SessionContext)
    

    const goto = (link) => {
        history.push(link);
    }

    const cerrarSesion = async () => {
        await Requester.get('usuarios/logout');
        localStorage.removeItem('loggedIn');
        history.push("/login")

    }

    return (
        <Container className="menuPrincipal" style={!isClosed ? ({ width: "20vw", boxShadow: "0px 0 10px 1px #8e8e8e" }) : ({ width: "3.25vw" })}>
            <Row style={{backgroundColor: "#e41b23"}}>
                <Col xs={2} className="stripes">
                    <img className="img-fluid isotipo" src={isotipo} atl="logo" onClick={() => setIsClosed(true)} />
                </Col>
            </Row>
            <Row style={{backgroundColor: "#e41b23"}}>
                <Col xs={2} className="stripe">
                    <IconButtonCustom id="mm1" faIcon={faTicketAlt} color="white" tooltip="Tickets" onClick={() => goto(`/${unidad}/tickets`)} style={style} />
                    <IconButtonCustom id="mm3" faIcon={faPuzzlePiece} color="white" tooltip="Armados" onClick={() => goto(`/${unidad}/armados`)} style={style} />
                    <IconButtonCustom id="mm3" faIcon={faTruck} color="white" tooltip="Seguimientos" onClick={() => goto(`/${unidad}/seguimientos`)} style={style} />
                    <IconButtonCustom id="mm2" allowAccess={allowAccess(permisos, 'clientes')} faIcon={faUserTie} color="white" tooltip="Clientes" onClick={() => goto(`/${unidad}/clientes`)} style={style} />
                    <IconButtonCustom id="mm3" allowAccess={allowAccess(permisos, 'agentes')} faIcon={faUsersCog} color="white" tooltip="Agentes" onClick={() => goto(`/${unidad}/usuarios`)} style={style} />                    
                    <IconButtonCustom id="mm4" allowAccess={allowAccess(permisos, 'libros')} faIcon={faBook} color="white" tooltip="Libros" onClick={() => goto(`/${unidad}/libros`)} style={style} />
                    <IconButtonCustom id="mm5" allowAccess={allowAccess(permisos, 'unidades')} faIcon={faBuilding} color="white" tooltip="Unidades de Negocio" onClick={() => goto(`/${unidad}/unidades`)} style={style} />                            
                    <IconButtonCustom id="mm6" allowAccess={allowAccess(permisos, 'unidades')} faIcon={faFileAlt} color="white" tooltip="Reportes" onClick={() => goto(`/${unidad}/reportes`)} style={style} />                            
                    <IconButtonCustom id="mm7" faIcon={faSignOutAlt} color="white" tooltip="Cerrar Sesión" onClick={cerrarSesion} style={style} />
                </Col>
            </Row>
        </Container>
    );
};

export default Menu;