import { useEffect, useState } from "react"
import Requester from "../../shared/Requester"


export default function HeaderProceso({unidad, procesoId}) {

    const [header, setHeader] = useState('')

    useEffect(() => {
    
        async function fetchHeader() {
            const response = await Requester.get(`proceso/${unidad}/${procesoId}/ticketAsunto`);
            setHeader(response.data.ticketAsunto)
        }

        if(unidad && procesoId){
            fetchHeader()
        }

    }, [unidad, procesoId])


    return (
        <div className="HeaderProceso">{header}</div>
    )
}