import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import UnidadComponentLoader from '../proceso/UnidadComponentLoader';

const Mediacion = React.lazy(() => import('./menus/menuProceso/MenuProcesoMediacion'))
const Correduria = React.lazy(() => import('./menus/menuProceso/MenuProcesoCorreduria'))

const ProcesoWrapper = (props) => {
    
    const {unidad} = useParams()
    

    const [idTicket, setIdTicket] = useState("");
    const [activePage, setActivePage] = useState("")

    useEffect(() => {
        const path = window.location.pathname.split("/");
        if (path.length === 5) {
            setIdTicket(path[path.length - 1]);
            setActivePage(path[path.length - 2]);
        } else {
            setActivePage(path[path.length - 1]);
        }
    }, [window.location.pathname])


    return (
        <div>
            <Row>
                <Col md={3}>
                    {/* <MenuProceso idUnidad={unidad} idTicket={idTicket} activePage={activePage} /> */}
                    <UnidadComponentLoader 
                        idUnidad={unidad} idTicket={idTicket} activePage={activePage}
                        correduria={Correduria}
                        mediacion={Mediacion}
                    ></UnidadComponentLoader> 
                </Col>
                <Col md={9}>
                    {props.children}
                </Col>
            </Row>
        </div>
    );
};

export default ProcesoWrapper;