import React, { useState, useEffect } from "react"
import { Col, Button, Input } from "reactstrap"


export default function SelectOne({ values, valueLabel, onSelect, readOnly, style }) {

    const [selected, setSelected] = useState({})

    useEffect(() => {

        if (values && values.length > 0) {
            setSelected(values[0])
        } else {
            setSelected({})
        }

    }, [values])

    function handleChange(e) {
        setSelected(values.find(v => v._id === e.target.value))
    }

    function handleClickAdd() {
        onSelect(selected)
    }

    return (
        <React.Fragment>
            <Col xs="auto">
                <Input type="select" className="FormInput"
                    value={selected._id}
                    onChange={handleChange}
                    disabled={readOnly}
                    style={style}>
                    {
                        values ? values.map(u => (
                            <option key={u._id} value={u._id}>{u[valueLabel]}</option>
                        )) : null
                    }
                </Input>
            </Col>
            <Col xs="3">
                <Button onClick={handleClickAdd} disabled={!selected._id || readOnly}>Agregar</Button>
            </Col>
        </React.Fragment>
    )

}