import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row, Input } from 'reactstrap';
import Header from '../shared/Header';
import Requester from '../shared/Requester'
import { Link, useHistory, useParams } from 'react-router-dom';
import SeguimientoPreview from './SeguimientoPreview';
import AbrirSeguimientoFormDialog from './AbrirSeguimientoFormDialog';

export default function SeguimientosList({ }) {

    const { unidad } = useParams()
    const history = useHistory()
    const [seguimientos, setSeguimientos] = useState([])

    useEffect(() => {

        async function fetchSeguimientos() {
            const response = await Requester.get(`seguimientos/${unidad}`)
            setSeguimientos(response.data)
        }

        fetchSeguimientos()

    }, [unidad])

    function handleNuevoSeguimiento(seguimiento) {
        history.push(`/${unidad}/seguimientos/${seguimiento._id}`)
    }

    return (
        <Container>
            <Header>Seguimientos</Header>
            <Row>
                <Col xs='auto'>
                    <AbrirSeguimientoFormDialog
                        unidad={unidad}
                        onSave={handleNuevoSeguimiento}
                        ></AbrirSeguimientoFormDialog>
                </Col>
            </Row>
            <Row>
                {
                    seguimientos.map(s =>
                        <Col xs={12} md={3}>
                            <SeguimientoPreview key={s._id} seguimiento={s}  />
                        </Col>)
                }
            </Row>
        </Container>
    )

}