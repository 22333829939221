import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Container, Row, Input } from 'reactstrap';
import ArmadoEnvioFormDialog from './ArmadoEnvioFormDialog';
import moment from 'moment';
import IconButtonCustom from '../shared/IconButtonCustom';
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';

export default function ArmadoPreview({ armado, onSent }) {

    const history = useHistory()
    const { unidad } = useParams()
    const [totalCompletos, setTotalCompletos] = useState(0)

    function getShowStatus(armado) {
        if (armado) {
            let status = "No enviado";
            if (armado.estatus === "Enviado") {
                if (armado.registros.some(r => r.estatus !== "Firmado mediador")) {
                    status = "En proceso";
                } else {
                    status = "Terminado";
                }
            }
            return status;
        } return null;
    }

    function getColor(status) {
        switch (status) {
            case "No enviado":
                return "#e41b23";
            case "En proceso":
                return "#fbbc04";
            case "Terminado":
                return "#86df8d";
            default:
                return "#e41b23";
        }
    }

    useEffect(() => {

        if (armado) {
            setTotalCompletos(armado.registros.filter(p => p.estatus === 'Firmado mediador').length)
        }

    }, [armado])

    function handleClickVer(e) {
        e.preventDefault()
        history.push(`/${unidad}/armados/${armado._id}`)
    }

    return (
        <div>
            <Container className="ticket wrapper pt-3 pb-3 mt-1 mb-1">
                <Row>
                    <Col className="col align-self-end ml-0 mr-0" xs={2} style={{ marginLeft: "auto" }}>
                        {
                            <IconButtonCustom
                                faIcon={faPuzzlePiece}
                                tooltip={getShowStatus(armado)}
                                color={getColor(getShowStatus(armado))}
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={5}>
                        Armado No.: {armado.numero}
                    </Col>
                    <Col xs={7} style={{ textAlign: 'right' }}>
                        Creado: {moment(armado.createdAt).format('DD/MM/YY')}
                    </Col>
                </Row>
                <Row>
                    <Col xs={5}>
                        {`${totalCompletos}/${armado.totalRegistros} registros`}
                    </Col>
                    <Col xs={7} style={{ textAlign: 'right' }}>
                        Terminado: {armado.terminatedAt ? moment(armado.terminatedAt).format('DD/MM/YY') : '-'}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {`${armado.estatus}`}
                    </Col>
                </Row>
                <Row className="ticket buttonWrapper">
                    <Col xs={6}>
                        <Button
                            color="secondary"
                            type="button"
                            style={{ margin: '20px 0' }}
                            onClick={handleClickVer}>Ver</Button>
                    </Col>
                    <Col xs={6}>
                        {
                            <ArmadoEnvioFormDialog
                                armado={armado}
                                onSave={onSent}
                                disabled={armado.terminatedAt ? true : false}>
                            </ArmadoEnvioFormDialog>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )

}