import { BrowserRouter as Router, Route, Switch, useRouteMatch } from "react-router-dom"
import UnidadesList from "./UnidadesList"
import FormaUnidad from "./FormaUnidad"


function Unidades() {

    let match = useRouteMatch();

    return (
        <>
            <Route exact path={`${match.path}`}>
                <UnidadesList />
            </Route>
            <Switch>
                <Route exact path={`${match.path}/agregar`}>
                    <FormaUnidad />
                </Route>
                <Route exact path={`${match.path}/:unidadId`}>
                    <FormaUnidad mode='edit'></FormaUnidad>
                </Route>
            </Switch>
        </>
    )
}

export default Unidades