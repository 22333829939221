import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Header from "../shared/Header";
import Requester from "../shared/Requester";
import usePost from "../shared/usePost";
import Swal from "sweetalert2";

const attrValues = {
    checkbox: 'checked',
    text: 'value',
    email: 'value',
    password: 'value'
}

function FormaUsuario({mode}) {

    const history = useHistory()
    const {unidad, usuarioId} = useParams()
    const [usuario, setUsuario] = useState({
        nombre: '',
        correo: '',
        contrasena: ''
    })
    const [saving, saveUsuario, ] = usePost('usuarios')

    useEffect(() => {

        async function fetchUsuario() {
            const response = await Requester.get(`usuarios/${usuarioId}`)
            setUsuario({...response.data, contrasena: ''})
        }

        if(mode === 'edit') {
            fetchUsuario();
        }

    }, [usuarioId])

    function handleChange(name) {
        return function (e) {
            setUsuario({ ...usuario, [name]: e.target[attrValues[e.target.type]] })
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        
        await saveUsuario(usuario);
        Swal.fire({
            icon: 'success',
            title: 'Guardado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        }).then(() => history.goBack());

    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header>
                {
                    mode !== 'edit' ? 'Nuevo agente' : 'Editar agente'
                }
            </Header>
            <FormGroup>
                <Container>
                    <Row>
                        <Col xs="3">
                            <Label for="nombre">Nombre *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="nombre" className="FormInput"
                                value={usuario.nombre}
                                onChange={handleChange('nombre')}
                                required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="correo">Correo *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="email" id="correo" className="FormInput"
                                value={usuario.correo}
                                onChange={handleChange('correo')} required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="contrasena">
                                {
                                    mode !== 'edit' ? 'Contraseña *' : 'Cambiar contraseña '
                                }
                            </Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="password" id="contrasena" className="FormInput"
                                value={usuario.contrasena}
                                required={mode !== 'edit'}
                                onChange={handleChange('contrasena')}></Input>
                        </Col>
                    </Row>                    
                    <br />
                    <br />
                    <Row>
                        <Col xs="10"></Col>
                        <Col xs="2">
                            <Button color="secondary" type="submit" disabled={saving}>Guardar</Button>
                        </Col>
                    </Row>
                </Container>
            </FormGroup>
        </Form>
    )

}

export default FormaUsuario;