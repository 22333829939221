import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Form } from "reactstrap";


export default function FormDialog({ title, children, body, data, submitLabel = 'Guardar' }) {

    const [isOpen, setIsOpen] = useState(false);
    const [callback, setCallback] = useState(null);

    function toggleIsOpen() {
        setIsOpen(!isOpen);
    }

    function show(handler) {
        return function (e) {
            e.preventDefault();
            e.stopPropagation();
            setCallback(() => handler);
            toggleIsOpen();
        }
    }

    async function handleClickConfirm(e) {
        e.stopPropagation();
        e.preventDefault();

        if (callback) {
            await callback(data);
        }
        toggleIsOpen();
    }

    return (
        <React.Fragment>
            {children(show)}
            <Modal isOpen={isOpen}>
                <Form onSubmit={handleClickConfirm}>
                    <ModalHeader toggle={toggleIsOpen}>
                        {title}
                    </ModalHeader>
                    <ModalBody>
                        {body}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">{submitLabel}</Button>
                        <Button color="seconday" onClick={toggleIsOpen}>Cancelar</Button>
                    </ModalFooter>
                </Form>
            </Modal>

        </React.Fragment>
    )

}
