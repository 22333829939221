import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";


function ConfirmDialog({title, children, body, when}) {

    const [isOpen, setIsOpen] = useState(false);
    const [callback, setCallback] = useState(null);

    function toggleIsOpen() {
        setIsOpen(!isOpen);
    }

    function show(handler) {
        return function(e) {     
            e.preventDefault();
            e.stopPropagation();
            setCallback(() => handler);

            if(when !== undefined) {
                if(!when && handler) {
                    handler()
                    return;
                }                
            }

            toggleIsOpen();
        }
    }

    function handleClickConfirm() {
        if(callback) {
            callback();
        }
        toggleIsOpen();
    }

    return (
        <React.Fragment>
            {children(show)}
            <Modal isOpen={isOpen}>
                <ModalHeader toggle={toggleIsOpen}>
                    {title}
                </ModalHeader>
                <ModalBody>
                    {body}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleClickConfirm}>Aceptar</Button>
                    <Button color="seconday" onClick={toggleIsOpen}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )

}

export default ConfirmDialog;