import React, {Suspense, useContext} from 'react';

import { SessionContext } from "../shared/session";


function Cargando() {
    return <div>Cargando...</div>
}

export default function UnidadComponentLoader({correduria :Correduria, mediacion :Mediacion, ...params}) {
    const {unidad} = useContext(SessionContext)    

    return (
        <Suspense fallback={<Cargando/>}>
            {
                unidad?.tipo === "Correduría" ?
                    <Correduria {...params}></Correduria>
                :
                unidad?.tipo === "Mediador Privado" ?
                    <Mediacion {...params}></Mediacion>
                : null
            }
        </Suspense>
    )
}