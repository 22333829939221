import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import FormDialog from "../shared/FormDialog";
import { v4 as uuidv4 } from 'uuid';


export default function ContactoClienteDialog({title, label, onSave, askDatosGenerales}) {

    const [data, setData] = useState({
        nombre: '',
        correo: '',
        datosGenerales: ''
    })

    function handleChange(name) {
        return function(e) {
            setData({...data, [name]:e.target.value})
        }
    }

    function handleConfirm(data) {
        onSave({...data, uuid: uuidv4()})
    }

    return (
        <FormDialog 
            title={title}
            data={data}
            body={
                <Container>
                    <Row>
                        <Col xs="3">
                            <Label for="nombre">Nombre *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="nombre" className="FormInput"
                                value={data.nombre}
                                onChange={handleChange('nombre')}
                                required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="correo">Correo *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="email" id="correo" className="FormInput"
                                value={data.correo}
                                onChange={handleChange('correo')}
                                required></Input>
                        </Col>
                    </Row>
                    {
                        askDatosGenerales ? 
                        <React.Fragment>
                            <br />
                            <Row>
                                <Col xs="4">
                                    <Label for="datosGenerales">Datos generales</Label>
                                </Col>
                                <Col xs="auto">
                                    <Input type="textarea" id="datosGenerales" className="FormInput"
                                        value={data.datosGenerales}
                                        onChange={handleChange('datosGenerales')}
                                        ></Input>
                                </Col>
                            </Row>
                            <br />
                        </React.Fragment> : null
                    }
                </Container>
        }>
            {
                confirm => (
                    <Button color="secondary" style={{ margin: '20px 0' }} onClick={confirm(handleConfirm)}>{label}</Button>
                )
            }
        </FormDialog>
    )

}