import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import NotasDetail from './NotasDetail';

const NotasModal = ({ isOpen, setIsOpen, ticket }) => {

    function toggleIsOpen() {
        setIsOpen(!isOpen);
    }

    function handleClickConfirm() {
        toggleIsOpen();
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} size="xl">
                <ModalHeader toggle={toggleIsOpen}>
                    Notas del Ticket #{ticket ? ticket.ticket : ''}
                </ModalHeader>
                <ModalBody>
                    <NotasDetail modulo="infoPreliminar" data={ticket.infoPreliminar} title="Información Preliminar" />
                    <NotasDetail modulo="actosPrevios" data={ticket.actosPrevios} title="Actos Previos" />
                    <NotasDetail modulo="tramitacion" data={ticket.tramitacion} title="Tramitación" />
                    <NotasDetail modulo="pagoFacturacion" data={ticket.pagoFacturacion} title="Pago y Facturación" />
                    <NotasDetail modulo="solicitudFolio" data={ticket.solicitudFolio} title="Solicitud Folio" />
                    <NotasDetail modulo="firma" data={ticket.firma} title="Firma" />
                    <NotasDetail modulo="actosPostFirma" data={ticket.actosPostFirma} title="Actos Post Firma" />
                    <NotasDetail modulo="entrega" data={ticket.entrega} title="Entrega" />
                    <NotasDetail modulo="copiasArchivo" data={ticket.copiasArchivo} title="Archivo y Copias" />
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleClickConfirm}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default NotasModal;