
import { Route, Switch, useRouteMatch } from "react-router-dom"
import ProcesoWrapper from "../shared/ProcesoWrapper";
import ActosPrevios from "./ActosPrevios";
import CopiasArchivo from "./CopiasArchivo";
import Entrega from "./Entrega";
import Firma from "./Firma";
import InfoPreliminar from "./InfoPreliminar";
import PagoFacturacion from "./PagoFacturacion";
import SolicitudFolio from "./SolicitudFolio";
import Tramitacion from "./Tramitacion";
import ActosPostFirma from "./ActosPostFirma";
import FormaCliente from "../clientes/FormaCliente";
import EliminarProceso from "./EliminarProceso";
import Certificaciones from "./Certificaciones";
import Testimonios from "./Testimonios";

function Proceso() {

    let match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route exact path={`${match.path}/nuevoCliente/:procesoId`}>
                    <ProcesoWrapper>
                        <FormaCliente></FormaCliente>
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/nuevoCliente`}>
                    <ProcesoWrapper>
                        <FormaCliente></FormaCliente>
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/infoPreliminar`}>
                    <ProcesoWrapper>
                        <InfoPreliminar></InfoPreliminar>
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/infoPreliminar/:procesoId`}>
                    <ProcesoWrapper>
                        <InfoPreliminar mode='edit'></InfoPreliminar>
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/actosPrevios/:procesoId`}>
                    <ProcesoWrapper>
                        <ActosPrevios></ActosPrevios>
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/tramitacion/:procesoId`}>
                    <ProcesoWrapper>
                        <Tramitacion></Tramitacion>
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/pagoFacturacion/:procesoId`}>
                    <ProcesoWrapper>
                        <PagoFacturacion></PagoFacturacion>
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/solicitudFolio/:procesoId`}>
                    <ProcesoWrapper>
                        <SolicitudFolio></SolicitudFolio>
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/firma/:procesoId`}>
                    <ProcesoWrapper>
                        <Firma></Firma>
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/actosPostFirma/:procesoId`}>
                    <ProcesoWrapper>
                        <ActosPostFirma></ActosPostFirma>
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/entrega/:procesoId`}>
                    <ProcesoWrapper>
                        <Entrega></Entrega>
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/copiasArchivo/:procesoId`}>
                    <ProcesoWrapper>
                        <CopiasArchivo></CopiasArchivo>
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/testimonios/:procesoId`}>
                    <ProcesoWrapper>
                        <Testimonios />
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/certificaciones/:procesoId`}>
                    <ProcesoWrapper>
                        <Certificaciones />
                    </ProcesoWrapper>
                </Route>
                <Route exact path={`${match.path}/eliminarProceso/:procesoId`}>
                    <ProcesoWrapper>
                        <EliminarProceso></EliminarProceso>
                    </ProcesoWrapper>
                </Route>
            </Switch>
        </>
    )
}

export default Proceso