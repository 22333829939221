import { useEffect, useState } from "react";
import Header from "../shared/Header";
import Requester from '../shared/Requester'
import { Table, Button, Row, Col } from 'reactstrap'
import IconButtonDelete from "../shared/IconButtonDelete";
import { Link, useHistory, useParams } from "react-router-dom";
import ConfirmDialog from "../shared/ConfirmDialog";
import FormattedDate from "../shared/FormattedDate";


function UnidadesList() {

    const [onlyActive, setOnlyActive] = useState(true)
    const [unidades, setUnidades] = useState([]);
    const history = useHistory()
    const {unidad} = useParams()

    useEffect(() => {

        async function fetchUnidades() {
            const response = await Requester.get(`unidades?onlyActive=${onlyActive}`)
            setUnidades(response.data)
        }

        fetchUnidades();

    }, [onlyActive])


    function handleClickDelete(_id) {
        return async function () {
            await Requester.post(`unidades/${unidad}/active`, {
                _id,
                active: false
            })
            setUnidades(unidades.filter(c => c._id !== _id))
        }
    }

    function toggleOnlyActive() {
        setOnlyActive(!onlyActive)
    }

    function handleRowClick(unidad) {
        return function (e) {
            history.push(`unidades/${unidad._id}`)
        }
    }

    return (
        <div>
            <Header>Unidades de negocio</Header>
            <div>
                <Row>
                    <Col xs='1'><strong>Unidades</strong></Col>
                    <Col xs='auto'>
                        <Button color="secondary" onClick={toggleOnlyActive} active={!onlyActive}>{onlyActive ? "Mostrar todos" : "Mostrar activos"}</Button>
                    </Col>
                    <Col xs='auto'>
                        <Link to="unidades/agregar">
                            <Button color="secondary">Agregar</Button>
                        </Link>
                    </Col>
                </Row>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Tipo</th>
                            <th>Prefijo Folios</th>
                            {/* <th>Sufijo</th> */}
                            <th>Fecha de Registro</th>
                            <th>Activo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            unidades.map(u => (
                                <tr key={u._id} onClick={handleRowClick(u)} style={{ cursor: 'pointer' }}>
                                    <td>{u.nombre}</td>
                                    <td>{u.tipo}</td>
                                    <td>{u.prefijo}</td>
                                    {/* <td>{u.sufijo}</td> */}
                                    <td>{FormattedDate(u.registro)}</td>
                                    <td>{u.active ? 'Si' : 'No'}</td>
                                    <td>
                                        <ConfirmDialog
                                            title="Dar de baja unidad de negocio"
                                            body={`Se dará de baja la unidad ${u.nombre}`}>
                                            {
                                                confirm => (
                                                    <IconButtonDelete onClick={confirm(handleClickDelete(u._id))}></IconButtonDelete>
                                                )
                                            }
                                        </ConfirmDialog>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default UnidadesList;