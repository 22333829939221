import { BrowserRouter as Router, Route, Switch, useRouteMatch, useParams } from "react-router-dom"
import ReportesList from "./ReportesList"
import FormaReporte from "./FormaReporte"


function Reportes() {

    let match = useRouteMatch();

    return (
        <>
            <Route exact path={`${match.path}`}>
                <ReportesList />
            </Route>
            <Switch>
                <Route exact path={`${match.path}/agregar`}>
                    <FormaReporte />
                </Route>
                <Route exact path={`${match.path}/:reporteId`}>
                    <FormaReporte mode='edit'></FormaReporte>
                </Route>
            </Switch>
        </>
    )
}

export default Reportes