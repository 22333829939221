import { faFileDownload, faTruck } from "@fortawesome/free-solid-svg-icons";
import FileSaver from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Input, Table, Row, Col, Button } from "reactstrap";
import Swal from "sweetalert2";
import ConfirmDialog from "../shared/ConfirmDialog";
import Header from "../shared/Header";
import IconButtonCustom from "../shared/IconButtonCustom";
import IconButtonDelete from "../shared/IconButtonDelete";
import Requester from "../shared/Requester";
import usePost from "../shared/usePost";
import ArmadoEnvioFormDialog from "./ArmadoEnvioFormDialog";
import JSZip from "jszip";

function b64toBlob(b64Data) {

    var byteString = atob(b64Data);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab]);
}

const SELECT_ESTATUS = ['En armado', 'En revisión', 'En sellado', 'En firma', 'Firmado mediador', 'Con observaciones']


export default function FormaArmado() {

    const { unidad, armadoId } = useParams();
    const [armado, setArmado] = useState({})

    const [saving, saveArmado,] = usePost(`armados/${unidad}/${armadoId}`)
    const [deleting, deleteProceso,] = usePost(`armados/${unidad}/${armadoId}/eliminarProceso`)
    const [working, setWorking] = useState(false)

    useEffect(() => {

        async function fetchArmado() {
            const response = await Requester.get(`armados/${unidad}/${armadoId}`)
            const orderedRegistros = response.data.registros.slice().sort((b, a) => new Date(b.proceso.actosPostFirma.citaCJAFechaMaxima) - new Date(a.proceso.actosPostFirma.citaCJAFechaMaxima));

            response.data.registros = [...orderedRegistros]

            console.log(response.data)
            setArmado(response.data)
        }

        if (deleting === false) {
            fetchArmado();
        }

    }, [unidad, armadoId, deleting])

    useEffect(() => {
        setWorking(deleting || saving)
    }, [deleting, saving])

    function handleChange(value, r) {
        return function (e) {
            setArmado({
                ...armado,
                registros: armado.registros.map(registro => {
                    if (registro._id === r._id) {
                        if (value === "estatus" && e.target.value !== "Con observaciones") {
                            return {
                                ...registro,
                                observaciones: "",
                                [value]: e.target.value
                            }
                        } else return {
                            ...registro,
                            [value]: e.target.value
                        }
                    }
                    return registro
                })
            })
        }
    }

    async function handleClickSave(e) {
        await saveArmado(armado)
        Swal.fire({
            icon: 'success',
            title: 'Guardado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        })
    }

    function onSent(e) {
        setArmado({ ...armado, estatus: 'Enviado' })
    }

    function handleClickDelete(r) {
        return function (e) {
            deleteProceso({
                procesoId: r.proceso._id
            })

        }
    }

    function handleClickDownloadControlArmado(r) {
        return async function (e) {
            const response = await Requester.get(`armados/${unidad}/${armado._id}/control/${r.proceso._id}`);
            FileSaver.saveAs(Requester.b64ToBlob(response.data.file), response.data.name)
        }
    }

    async function handleClickDownloadAll() {
        setWorking(true);
        const zip = new JSZip();
        for(let r of armado.registros) {
            console.log("DESCARGANDO .... ", r.proceso._id)
            const response = await Requester.get(`armados/${unidad}/${armado._id}/control/${r.proceso._id}`)            
            zip.file(response.data.name, b64toBlob(response.data.file));            
        }        
        zip.generateAsync({ type: "blob" }).then(content => {
            FileSaver.saveAs(content, `Controles_Armado_${armado.numero}.zip`);
            setWorking(false);
        });
    }

    return (
        <div>
            <Header>Armado {armado.numero}</Header>
            <Container>
                <Row>
                    <Col xs="9"></Col>
                    <Col xs="3" style={{textAlign: 'right'}}>
                        <Button color="secondary"
                            style={{ margin: '20px 0' }}
                            disabled={working} onClick={handleClickDownloadAll}>Descargar Controles</Button>
                    </Col>
                </Row>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Ticket</th>
                            <th>Folio</th>
                            <th>Limite Cita CJA</th>
                            <th>Estatus</th>
                            <th>Observaciones</th>
                            <th>Agente</th>
                            <th>El día</th>
                            <th style={{ textAlign: 'center' }}>Control armado</th>
                            <th style={{ textAlign: 'center' }}>Ticket pasó a</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            armado.registros?.map(r =>
                                <tr key={r._id}>
                                    <td>
                                        <a href={`/${unidad}/proceso/tramitacion/${r.proceso._id}`} target="_blank" rel="noreferrer">{r.proceso.ticket}</a>
                                    </td>
                                    <td>{r.proceso.solicitudFolio.folio}</td>
                                    <td>
                                        {moment(r.proceso.actosPostFirma.citaCJAFechaMaxima).format("DD/MM/YY")}
                                    </td>
                                    <td>
                                        <Input type="select" className="FormInput" style={{ width: '200px' }}
                                            value={r.estatus} onChange={handleChange('estatus', r)}>
                                            {
                                                SELECT_ESTATUS ? SELECT_ESTATUS.map(e => (
                                                    <option key={e} value={e}>{e}</option>
                                                )) : null
                                            }
                                        </Input>
                                    </td>
                                    <td>
                                        <Input type="text" className="FormInput" value={r.observaciones}
                                            onChange={handleChange('observaciones', r)}
                                            style={{ width: '200px' }} disabled={r.estatus !== "Con observaciones"} />
                                    </td>
                                    <td>
                                        {r.fechaModificacion?.agente.nombre}
                                    </td>
                                    <td>
                                        {r.fechaModificacion && r.fechaModificacion.fecha ? moment(r.fechaModificacion.fecha).format("DD/MM/YY") : '-'}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <IconButtonCustom onClick={handleClickDownloadControlArmado(r)}
                                            faIcon={faFileDownload}
                                            color="black"
                                            tooltip="Control de armado"
                                        />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        { r.estatus === "Firmado mediador" &&
                                            <IconButtonCustom color="#e41b23" faIcon={faTruck} />
                                        }
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <ConfirmDialog
                                            title="Quitar registro"
                                            body={`Se eliminará el registro ${r.proceso.ticket}`}>
                                            {
                                                confirm => (
                                                    <IconButtonDelete onClick={confirm(handleClickDelete(r))}></IconButtonDelete>
                                                )
                                            }
                                        </ConfirmDialog>
                                    </td>
                                </tr>)
                        }
                    </tbody>
                </Table>
                <br />
                {/* {
                    armado.estatus === 'Pendiente' && */}
                <Row>
                    <Col xs="8"></Col>
                    <Col xs="2">
                        <ArmadoEnvioFormDialog
                            disabled={working}
                            armado={armado}
                            label={'Enviar'}
                            onSave={onSent}></ArmadoEnvioFormDialog>
                    </Col>
                    <Col xs="2">
                        <Button color="secondary"
                            style={{ margin: '20px 0' }}
                            disabled={working} onClick={handleClickSave}>Guardar</Button>
                    </Col>
                </Row>
                {/* }      */}
            </Container>
        </div>
    )

}