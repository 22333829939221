import { faTruck, faTruckFast } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Container, Row, Input } from 'reactstrap';
import IconButtonCustom from '../shared/IconButtonCustom';


export default function SeguimientoPreview({ seguimiento, onSent }) {

    const history = useHistory()
    const { unidad } = useParams()



    function handleClickVer(e) {
        e.preventDefault()
        history.push(`/${unidad}/seguimientos/${seguimiento._id}`)
    }

    function getShowStatus(seguimiento) {
        let status = "En trámite";
        if(seguimiento.folios.some(f => !f.rechazado && (!f.inscripcionCJA || !f.numeroRegistro))) {
            if(seguimiento.estatus !== "En trámite") {
                status = "En proceso"
            }
        } else {
            status = "Terminado";
        }
        return status
    }

    function getColor(status) {
        switch (status) {
            case "En proceso":
                return "#d9d200";
            case "En trámite":
                return "#ff5200";
            case "Terminado":
                return "#86df8d";
            default:
                return "#e41b23";
        }
    }

    return (
        <div>
            <Container className="ticket wrapper pt-3 pb-3 mt-1 mb-1">
                <Row>
                    <Col className="col align-self-end ml-0 mr-0" xs={2} style={{ marginLeft: "auto" }}>
                        {
                            <IconButtonCustom
                                faIcon={getShowStatus(seguimiento) === "Terminado" ? faTruck : faTruckFast}
                                tooltip={getShowStatus(seguimiento)}
                                color={getColor(getShowStatus(seguimiento))}
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Seguimiento No.: {seguimiento.numero}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Folios controlados: {seguimiento.folios?.length}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Estatus: {seguimiento.estatus}
                    </Col>
                </Row>
                <Row className="ticket buttonWrapper">
                    <Col xs={6}>
                        <Button color="secondary" type="button" style={{ margin: '20px 0' }} onClick={handleClickVer}>Ver</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}