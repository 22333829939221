import React from "react";
import { useParams } from "react-router-dom";
import usePost from "../shared/usePost";
import SelectAgenteFormDialog from "../shared/SelectAgenteFormDialog";


export default function InformarCJAFormDialog({seguimientoId, onSave, disabled = false}) {
    
    const {unidad} = useParams()
    const [working, solicitarCita,] = usePost(`seguimientos/${unidad}/${seguimientoId}/notificaciones/cita`)    

    async function handleConfirm(agenteSeleccionado) {
        await solicitarCita({
            agente: agenteSeleccionado
        })
        onSave({})
    }

    return (
        <SelectAgenteFormDialog
            title="Enviar notificación para solicitar cita CJA"
            submitLabel="Enviar"
            body={
                <p>
                    Selecciona el agente al que quieres enviar esta noticicación
                </p>
                }
            onConfirm={handleConfirm}
            buttonLabel={'Informar'}
            disabled={working || disabled}>

        </SelectAgenteFormDialog>
    )

}