import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

export default function FormattedDatePicker({ selected, readOnly, dateFormat = "dd/MM/yyyy", disabled, ...rest }) {

    const [date, setDate] = useState('');

    useEffect(() => {
        if (selected) {
            setDate(new Date(selected))
        }
    }, [selected])

    return <DatePicker selected={date} {...rest} disabled={readOnly || disabled} dateFormat="dd-MM-yyyy"></DatePicker>

}