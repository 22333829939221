import React, { useEffect, useState } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import FormattedDate from "./FormattedDate";

function Wrapper({wrap, children}) {
    if(wrap) {
        return <Container>
            <Row>{children}</Row>
        </Container>
    }
    return <React.Fragment>{children}</React.Fragment>
}


export default function CheckboxDate({label, date, onChange, readOnly, wrap= true}) {

    const [active, setActive] = useState(false)

    useEffect(() => {
        if(date) {
            setActive(true)
        } else {
            setActive(false)
        }
    }, [date])

    function handleChange(e) {        
        if(e.target.checked) {
            onChange(new Date());
        } else {
            onChange(null);
        }
    }

    return (
        <Wrapper wrap={wrap}>
                {
                    label ? 
                    <Col>
                        <Label>{label}</Label>
                    </Col> : null
                }
                <Col>
                    {
                        active && !readOnly?
                        <Input type="text" value={FormattedDate(date)} disabled={true}></Input> :
                        <Input type="text" value="" readOnly={true}></Input>
                    }
                    
                </Col>
                <Col>
                    <Input type="checkbox" checked={active} onChange={handleChange} disabled={readOnly}></Input>
                </Col>                
        </Wrapper>
    )

}