import React, { useEffect, useState } from "react";
import { Prompt, useParams } from "react-router";
import { Container, Form, FormGroup, Button, Row, Col, Table, Label } from "reactstrap";
import Header from "../shared/Header";
import Requester from "../shared/Requester";
import usePost from "../shared/usePost";
import moment from 'moment'
import Notas from "./Notas";
import SelectOne from "./components/SelectOne";
import CheckboxDate from "../shared/CheckboxDate";
import FilePicker from "../shared/FilePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import FileSaver from "file-saver";
import Swal from "sweetalert2";
import AuthenticatedInput from "../shared/AuthenticatedInput";
import useReadOnly from "../shared/useReadOnly";
import FixedSubmitButton from "./components/FixedSubmitButton";
import HeaderProceso from "./components/HeaderProceso";
import IconButtonDelete from "../shared/IconButtonDelete";

function allowWrite(permisos) {
    return permisos.admin || permisos['entrega'] === 'write'
}

const attrValues = {
    checkbox: 'checked',
    text: 'value'
}

const Input = AuthenticatedInput(allowWrite)

function b64toBlob(b64Data) {

    var byteString = atob(b64Data);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab]);
}


export default function Entrega({ }) {

    const { unidad, procesoId } = useParams();
    const readOnly = useReadOnly(allowWrite)
    const [numImpresiones, setNumImpresiones] = useState(0);
    const [entrega, setEntrega] = useState({
        constancia: false,
        constanciaRPP: null,
        acuses: [],
        notas: []
    })

    const [firmantes, setFirmantes] = useState([])
    const [blockNavigation, setBlockNavigation] = useState(false)
    const [saving, saveEntrega,] = usePost(`proceso/${unidad}/entrega`)

    useEffect(() => {

        async function fetchProceso() {
            const response = await Requester.get(`proceso/${unidad}/entrega/${procesoId}`);
            const data = response.data.entrega;

            if (data.notas) {
                data.notas = data.notas.map(n => {
                    n.fecha = new Date(n.fecha)
                    return n;
                })
            }

            setNumImpresiones(response.data.infoPreliminar.numeroImpresiones)

            setEntrega({ ...entrega, ...data })

            const responseFirmantes = await Requester.get(`proceso/${unidad}/${procesoId}/firmantes`);
            setFirmantes(responseFirmantes.data.firmantes.filter(f => !data.acuses.find(a => a.nombre === f.nombre)))
        }


        fetchProceso()

    }, [unidad, procesoId])

    function handleChangeAcuse(a, name) {
        return function (e) {
            setBlockNavigation(true)
            const acuses = entrega.acuses.map(ca => {
                if (ca.nombre === a.nombre) {
                    const val = { ...a, [name]: e };
                    if (name === 'entregado' || name === 'notificacion') {
                        val[`${name}Fecha`] = new Date()
                    }
                    return val;
                }
                return ca;
            })
            setEntrega({ ...entrega, acuses })
        }
    }

    function handleChangeNotas(e) {
        setBlockNavigation(true)
        setEntrega({ ...entrega, notas: e })
    }

    function handleChange(name) {
        return function (e) {
            setEntrega({ ...entrega, [name]: e.target[attrValues[e.target.type]] })
        }
    }

    function handleChangeFile(name) {
        return function (file) {
            setEntrega({ ...entrega, [name]: file })
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        saveEntrega({
            ...entrega, firmantes
        })
        setBlockNavigation(false)
        Swal.fire({
            icon: 'success',
            title: 'Guardado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        })
    }

    function handleClickAgregar(firmante) {
        setBlockNavigation(true)
        if (entrega.acuses.length >= numImpresiones - 2) {
            Swal.fire({
                icon: 'warning',
                title: 'No quedan impresiones para entregar',
                text: 'Solicita una copia certificada',
                confirmButtonText: 'Continuar',
                confirmButtonColor: '#6c757d',
            })
            return
        }
        const acuses = [...entrega.acuses, { ...firmante, createdAt: new Date() }];
        setEntrega({ ...entrega, acuses })
        setFirmantes(firmantes.filter(f => !acuses.find(a => f.nombre === a.nombre)))
    }

    function handleClickEliminar(firmante) {
        setBlockNavigation(true)
        console.log(entrega.acuses);
        const acuses = entrega.acuses.filter(f => f !== firmante);
        console.log(entrega.acuses);
        setEntrega({ ...entrega, acuses })

        async function fetchFirmantes() {
            const responseFirmantes = await Requester.get(`proceso/${unidad}/${procesoId}/firmantes`);
            setFirmantes(responseFirmantes.data.firmantes.filter(f => !acuses.find(a => a.nombre === f.nombre)))
        }

        fetchFirmantes();
    }

    function handleClickDownload(a, name) {
        return async function (e) {
            if (name === 'acuse') {
                const response = await Requester.post(`proceso/${unidad}/${procesoId}/generar/acuseEntrega`, { acuse: a });
                FileSaver.saveAs(b64toBlob(response.data.file), response.data.name)
            }
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Prompt
                when={blockNavigation}
                message={location => `¿Salir sin guardar cambios?`}
            ></Prompt>
            <HeaderProceso unidad={unidad} procesoId={procesoId}></HeaderProceso>
            <Header>
                Entrega
            </Header>
            <FormGroup className='GreyTop'>
                <Container>
                    <Row>
                        <Col><p style={{ fontWeight: 'bold' }}>Elaborar acuse de entrega para:</p></Col>
                        <SelectOne values={firmantes} valueLabel="nombre" onSelect={handleClickAgregar} readOnly={readOnly}></SelectOne>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Table>
                            <thead>
                                <tr style={{ textAlign: "center" }}>
                                    <th>Nombre</th>
                                    <th>Listo para entrega</th>
                                    <th>Descargar acuse</th>
                                    <th>Notificación para entrega</th>
                                    <th>Acuse Firmado</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "center" }}>
                                {
                                    entrega.acuses.map(u => (
                                        <tr key={u._id}>
                                            <td>{u.nombre}</td>
                                            <td>
                                                <CheckboxDate
                                                    date={u.preparadoFecha}
                                                    onChange={handleChangeAcuse(u, 'preparadoFecha')}
                                                    readOnly={readOnly}></CheckboxDate>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {
                                                    u.preparadoFecha ?
                                                        <FontAwesomeIcon icon={faFileAlt} style={{ cursor: 'pointer' }}
                                                            onClick={handleClickDownload(u, 'acuse')}></FontAwesomeIcon>
                                                        :
                                                        <span>No está listo</span>
                                                }
                                            </td>
                                            <td>
                                                <FilePicker
                                                    route={`proceso/${unidad}/entrega/files/${entrega._id}`}
                                                    fileUrl={u.notificacionUrl}
                                                    customLabel={u.notificacionFecha ? moment(u.notificacionFecha).format("DD-MM-YYYY") : null}
                                                    onChange={handleChangeAcuse(u, 'notificacion')}
                                                    readOnly={readOnly}
                                                    disabled={!u.preparadoFecha}
                                                >Adjuntar</FilePicker>
                                            </td>
                                            <td>
                                                <FilePicker
                                                    route={`proceso/${unidad}/entrega/files/${entrega._id}`}
                                                    fileUrl={u.entregadoUrl}
                                                    customLabel={u.entregadoFecha ? moment(u.entregadoFecha).format("DD-MM-YYYY") : null}
                                                    onChange={handleChangeAcuse(u, 'entregado')}
                                                    readOnly={readOnly}
                                                    disabled={!u.preparadoFecha}
                                                >Adjuntar</FilePicker>
                                            </td>
                                            <td>
                                                <IconButtonDelete onClick={() => handleClickEliminar(u)} />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>

                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="constanciaRpp">Constancia RPP Entregada</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="checkbox" id="constancia"
                                checked={entrega.constancia}
                                onChange={handleChange('constancia')}>
                            </Input>
                        </Col>
                        <Col xs="auto" hidden={!entrega.constancia}>
                        <FilePicker
                            route={`proceso/${unidad}/entrega/files/${entrega._id}`}
                            fileUrl={entrega.constanciaRPPUrl}
                            onChange={handleChangeFile('constanciaRPP')}>Adjuntar</FilePicker>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                    <Notas unidad={unidad} tipo="entrega" notasProceso={entrega.notas} onChange={handleChangeNotas}
                        readOnly={readOnly}></Notas>
                    <br />
                    <br />
                    <FixedSubmitButton saving={saving} readOnly={readOnly}></FixedSubmitButton>
                </Container>
            </FormGroup>
        </Form>
    )
}