import { useEffect, useState, useContext } from "react";
// import { useContext } from "react/cjs/react.development";
import { Input } from "reactstrap";
import { SessionContext } from "./session";

export default function AuthenticatedInput(allowWrite) { 

    return function AuthInput(props) {

        const [readOnly, setReadOnly] = useState(true);
        const session = useContext(SessionContext)

        useEffect(() => {

            if(session.permisos && allowWrite) {
                setReadOnly(!allowWrite(session.permisos));
            }

        }, [session.permisos])

        return <Input {...props} disabled={props.disabled || readOnly}></Input>
    }

}