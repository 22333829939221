import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Input, Row, Table } from "reactstrap";
import ConfirmDialog from "../../shared/ConfirmDialog";
import IconButtonDelete from "../../shared/IconButtonDelete";
import Subtitle from "../../shared/Subtitle";
import FormattedDatePicker from "../../shared/FormattedDatePicker";


export default function FechasFirmaTramitacion({ fechasFirma, onChange, unidad, readOnly }) {


    function handleClickDelete(index) {
        return function () {
            onChange(fechasFirma.filter((f, i) => i !== index))
        }
    }

    function handleClickAdd() {
        onChange([
            ...fechasFirma, {
                fecha: new Date(),
                hora: '00:00'
            }
        ])
    }

    function handleChange(index, name) {
        return function (event) {
            let value = null;
            switch (name) {
                case 'hora':
                    value = event.target.value;
                    break;
                default:
                    value = event;
                    break;
            }
            const newFechas = fechasFirma.map((ff, i) => i === index ? { ...ff, [name]: value } : ff)
            onChange(newFechas);
        }
    }

    return (
        <div>
            <div>
                <Row className="GreyTop">
                    <Col>
                        <Subtitle>Fechas de firma</Subtitle>
                    </Col>
                    <Col>
                        {
                            !readOnly ?
                                <Button onClick={handleClickAdd}>Agregar</Button> : null
                        }
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </div>
            <br />
            <Table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Hora</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        fechasFirma.map((f, i) => (
                            <tr key={i}>
                                <td>
                                    <FormattedDatePicker selected={f.fecha} onChange={handleChange(i, 'fecha')}></FormattedDatePicker>
                                </td>
                                <td>
                                    <Input type="time" value={f.hora} onChange={handleChange(i, 'hora')}></Input>
                                </td>
                                <td>
                                    <FontAwesomeIcon icon={faCalendar} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
                                </td>
                                <td>
                                    {
                                        !readOnly ?
                                            <ConfirmDialog
                                                title="Eliminar fecha"
                                                body={`Se eliminará la fecha de firma`}>
                                                {
                                                    confirm => (
                                                        <IconButtonDelete onClick={confirm(handleClickDelete(i))}></IconButtonDelete>
                                                    )
                                                }
                                            </ConfirmDialog> : null
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    )

}