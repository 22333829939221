import { BrowserRouter as Router, Route, Switch, useRouteMatch } from "react-router-dom"
import FormaCliente from "./FormaCliente";
import ClientesList from "./ClientesList"


function Clientes() {

    let match = useRouteMatch();

    return (
        <>
            <Route exact path={`${match.path}`}>
                <ClientesList></ClientesList>
            </Route>
            <Switch>
                <Route exact path={`${match.path}/agregar`}>
                    <FormaCliente></FormaCliente>
                </Route>
                <Route exact path={`${match.path}/:clienteId`}>
                    <FormaCliente mode='edit'></FormaCliente>
                </Route>
            </Switch>
        </>
    )
}

export default Clientes