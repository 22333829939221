import { Button } from "reactstrap";


export default function FixedSubmitButton({saving, readOnly}) {

    return (
        <div style={{position:'fixed', bottom:'50px', right:'-30px' }}>
                {
                    !readOnly ? 
                    <Button color="secondary" 
                        style={{
                            width: '180px',
                            padding: '10px',
                            paddingLeft: '30px',
                            borderRadius: '20px',
                            textAlign: 'left',
                            fontWeight: 'bold',
                        }}
                        type="submit" disabled={saving}>Guardar</Button> : null
                }
        </div>
    )

}