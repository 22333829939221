import React, { useState, useEffect, useContext } from 'react';
import { Button, Col, Container, Row, Input } from 'reactstrap';
import TiketPreview from './TiketPreview';
import Header from '../shared/Header';
import Requester from '../shared/Requester';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../shared/Spinner';
import { SessionContext } from "../shared/session";
import ReactPaginate from 'react-paginate';


const Main = () => {

    const { unidad } = useParams()
    const session = useContext(SessionContext)
    const history = useHistory()    
    const [isWorking, setIsWorking] = useState(false);
    const [ticketNumber, setTicketNumber] = useState(0);
    const limit = 40;
    const [query, setQuery] = useState("");

    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        if (unidad) {
            async function getTicketCount() {
                const response = await Requester.get(`proceso/${unidad}/count`)
                setTicketNumber(response.data)
            }

            getTicketCount()
            handleFetchTickets()
        }
    }, [unidad])

    async function handleFetchTickets() {
        setIsWorking(true);
        let url = `proceso/${unidad}?skip=0&limit=${encodeURIComponent(limit)}`
        if (query !== "") url += `&query=${encodeURIComponent(query)}`
        const response = await Requester.get(url)
        setTickets(response.data.procesos)
        setTicketNumber(response.data.count)
        setPageCount(Math.ceil(response.data.count / limit));
        setIsWorking(false);
    }

    async function fetchTickets(skip = 0, clear = false) {
        setIsWorking(true);
        let url = `proceso/${unidad}?skip=${encodeURIComponent(skip)}&limit=${encodeURIComponent(limit)}`
        if (query !== "" && !clear) url += `&query=${encodeURIComponent(query)}`
        const response = await Requester.get(url)
        setTickets(response.data.procesos)
        setTicketNumber(response.data.count)
        setPageCount(Math.ceil(response.data.count / limit));
        setIsWorking(false);
    }

    function clear() {
        setQuery("");
        fetchTickets(0, true);
    }

    useEffect(() => {
        if (tickets.length) {
            setIsWorking(false);
        }
    }, [tickets])

    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        fetchTickets(itemOffset)
        setPageCount(Math.ceil(ticketNumber / limit));
    }, [itemOffset, limit]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * limit) % ticketNumber;
        console.log(
            // `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    return (
        <React.Fragment>
            <Container>
                <Header>Tickets</Header>
                <Row className="mt-3 mb-3">
                    <Col md={4}>
                        <Input value={query} onChange={ e => setQuery(e.target.value)} placeholder="Buscar ticket..." />
                    </Col>
                    <Col md={2}>
                        <Button type="button" color="danger" onClick={handleFetchTickets}>Buscar</Button>
                    </Col>
                    <Col md={2}>
                        <Button type="button" color="secondary" onClick={clear}>Limpiar</Button>
                    </Col>
                </Row>
                {
                    session?.permisos?.admin || session?.permisos?.['infoPreliminar'] === 'write' ? 
                    <Row className="mt-3 mb-3">
                        <Col>
                            <Button type="button" color="danger" onClick={() => history.push(`proceso/infoPreliminar`)}>Agregar</Button>
                        </Col>
                    </Row>
                    : null
                }
                <Spinner isWorking={isWorking} />
                <ReactPaginate
                    className='paginate'
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                />
                <Row>
                    {tickets?.map(t => (
                        <Col xs={12} md={3}>
                            <TiketPreview ticket={t} onUpdate={fetchTickets} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Main;