 import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
import ConfirmDialog from "../../shared/ConfirmDialog";
import IconButtonDelete from "../../shared/IconButtonDelete";
import Requester from "../../shared/Requester";
import Subtitle from "../../shared/Subtitle";
import JSZip from "jszip";
import AuthenticatedInput from "../../shared/AuthenticatedInput";

function b64toBlob(b64Data) {

    var byteString = atob(b64Data);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab]);
}

function allowWrite(permisos) {
    return permisos.admin || permisos['tramitacion'] === 'write'
}

const Input = AuthenticatedInput(allowWrite)

export default function DocumentosTramitacion({ title='Documentos', documentos, onChange, tramitacionId, unidad, readOnly, 
    showIntegracion=false }) {


    const [uuid,] = useState(uuidv4)
    const [working, setWorking] = useState(false);
    const [dirty, setDirty] = useState(false);

    useEffect(() => {

        // documentos has an element with a b64 attribute
        // if it has a b64 attribute, it means that it has been uploaded
        // and we dirty is true, if not, we are not dirty
        if (documentos.length > 0) {
            setDirty(documentos.some(d => d.b64 !== undefined));
        }

    }, [documentos])

    function handleChangeFile(event) {

        if (!event.target.files || !event.target.files[0]) {
            return;
        }

        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onloadend = () => {
            const b64 = reader.result
                .replace("data:", "")
                .replace(/^.+,/, "");

            onChange([...documentos, {
                b64,
                nombre: file.name,
                tipo: file.name.split('.')[1].toUpperCase()
            }])

        };

        reader.readAsDataURL(file);
    }

    function handleChangeIntegracion(index) {
        return function(event) {
            const newDocumentos = documentos.map((doc, i) => {
                if (index === i) {
                    return {...doc, integracion: event.target.value}
                }
                return doc
            })
            onChange(newDocumentos)
        }
    }

    function handleClickDelete(index) {
        return function (e) {
            onChange(documentos.filter((d, i) => i !== index))
        }
    }

    function handleClickDownload(index) {
        return async function (e) {
            const d = documentos[index];
            const response = await Requester.get(`proceso/${unidad}/tramitacion/files/${tramitacionId}/${d.documentoUrl}`)
            FileSaver.saveAs(b64toBlob(response.data.file), response.data.name)
        }
    }

    async function handleClickDownloadAll() {
        setWorking(true);
        const zip = new JSZip();
        for(let d of documentos) {
            const response = await Requester.get(`proceso/${unidad}/tramitacion/files/${tramitacionId}/${d.documentoUrl}`)            
            zip.file(response.data.name, b64toBlob(response.data.file));            
        }        
        zip.generateAsync({ type: "blob" }).then(content => {
            FileSaver.saveAs(content, `Documentos_${moment().format('YYYY-MM-DD')}.zip`);
            setWorking(false);
        });
    }


    return (
        <div>
            <div>
                <Row>
                    <Col>
                        <Subtitle>{title}</Subtitle>
                    </Col>
                    <Col>
                        {
                            !readOnly ?
                                <div>
                                    <input
                                        type="file"
                                        onChange={handleChangeFile}
                                        style={{ display: 'none' }}
                                        id={uuid} />
                                    <label htmlFor={uuid} style={{ marginBottom: 0 }}>
                                        <span className="btn btn-secondary"
                                            style={{ cursor: 'pointer' }}>{'Adjuntar'}
                                        </span>
                                    </label>
                                </div> : null
                        }
                    </Col>
                    <Col>
                        <Button onClick={handleClickDownloadAll} disabled={dirty || working || documentos.length === 0}>Descargar todo</Button>
                    </Col>
                </Row>
            </div>
            <br />
            <Table>
                <thead>
                    <tr>
                        <th>Nombre archivo</th>                        
                        <th>Fecha</th>
                        <th>Agente</th>
                        {
                            showIntegracion ?
                            <th>Integración</th> : null
                        }
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        documentos.map((d, i) => (
                            <tr key={i}>
                                <td>{d.nombre}</td>
                                <td>{moment(d.fecha).format("DD-MM-YYYY")}</td>
                                <td>{d.usuario?.nombre}</td>
                                {
                                    showIntegracion ?
                                    <td>
                                        <Input type="text" value={d.integracion} onChange={handleChangeIntegracion(i)}/>
                                    </td> : null
                                }
                                <td>
                                    <FontAwesomeIcon icon={faDownload} style={{ cursor: 'pointer' }}
                                        onClick={handleClickDownload(i)}></FontAwesomeIcon>
                                </td>
                                <td>
                                    {
                                        !readOnly ?
                                            <ConfirmDialog
                                                title="Eliminar documento"
                                                body={`Se eliminará el documento ${d.nombre}`}>
                                                {
                                                    confirm => (
                                                        <IconButtonDelete onClick={confirm(handleClickDelete(i))}></IconButtonDelete>
                                                    )
                                                }
                                            </ConfirmDialog> : null
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    )

}