import axios from 'axios'

async function get(path) {
    console.log(`GET ${process.env.REACT_APP_BACKEND}/${path}`)    
    try {
        return await axios.get(`${process.env.REACT_APP_BACKEND}/${path}`, {withCredentials: true})
    } catch(e) {
        if(e?.response?.status === 401) {
            localStorage.removeItem('loggedIn');
            window.location.href = '/login'
            return;
        }

        if(e?.response?.status === 400) {
            debugger
            window.location.href = '/'
            return;
        }

        throw(e);
    }   
}

async function post(path, body, options = {}) {
    console.log(`POST ${process.env.REACT_APP_BACKEND}/${path}`)
    try {
        return await axios.post(`${process.env.REACT_APP_BACKEND}/${path}`, body, {
            withCredentials: true,
            ...options
        })
    } catch(e) {
        if(e?.response?.status === 401) {
            localStorage.removeItem('loggedIn');
            window.location.href = '/login'
            return;
        }

        if(e?.response?.status === 400) {
            debugger
            window.location.href = '/'
            return;
        }

        throw(e);
    }   
}

function b64ToBlob(b64Data) {

    var byteString = atob(b64Data);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab]);
}

export default {
    get,
    post,
    b64ToBlob
}