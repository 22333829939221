import { useState } from "react";
import { Col, Container, Form, FormGroup, Row, Label, Input, Button } from "reactstrap";
import Header from "./shared/Header";
import Requester from "./shared/Requester";
import usePost from "./shared/usePost";
import Swal from "sweetalert2";

export default function Login() {

    const [working, doLogin,] = usePost('usuarios/login')
    const [userData, setUserData] = useState({
        correo: '',
        password: ''
    })

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        await Requester.get('usuarios/logout')

        try {
            const res = await doLogin(userData);
            if (res) {
                localStorage.setItem('loggedIn', 'loggedIn');
                localStorage.setItem('psaAgent', userData.correo);
                window.location.href = '/'
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Datos incorrectos',
                    text: 'El usuario y/o la contraseña son incorrectos',
                    confirmButtonText: 'Continuar',
                    confirmButtonColor: '#6c757d',
                    timer: 5000
                })
            }
        } catch (err) {
            console.log(err);
        }
    }

    function handleChange(name) {
        return function (e) {
            setUserData({ ...userData, [name]: e.target.value });
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Header>Iniciar sesión</Header>
                <FormGroup>
                    <Container>
                        <Row>
                            <Col xs="3">
                                <Label for="correo">Correo *</Label>
                            </Col>
                            <Col xs="auto">
                                <Input type="email" id="correo" className="FormInput"
                                    value={userData.nombre}
                                    onChange={handleChange('correo')}
                                    required></Input>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs="3">
                                <Label for="password">Contraseña *</Label>
                            </Col>
                            <Col xs="auto">
                                <Input type="password" id="password" className="FormInput"
                                    value={userData.nombre}
                                    onChange={handleChange('password')}
                                    required></Input>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                            <Col xs="10"></Col>
                            <Col xs="2">
                                <Button color="secondary" type="submit" disabled={working}>Iniciar sesión</Button>
                            </Col>
                        </Row>
                    </Container>
                </FormGroup>
            </Form>
        </div>
    )

}