import { useContext, useEffect, useState } from "react";
import { SessionContext } from "./session";


export default function useReadOnly(allowWrite) {

    const [readOnly, setReadOnly] = useState(true);
    const session = useContext(SessionContext);

    useEffect(() => {

        if(session.permisos && allowWrite) {
            setReadOnly(!allowWrite(session.permisos));
        }

    }, [session.permisos])

    return readOnly

}