import React from 'react';
import Spin from '../assets/spin.gif';
import { Row, Col } from 'reactstrap';

const Spinner = ({ isWorking = false }) => {
    return (
        <Row hidden={!isWorking}>
            <Col xs={5}></Col>
            <Col xs={2}>
                <img src={Spin} alt="cargando..." width={150} />
            </Col>
        </Row>
    );
};

export default Spinner;