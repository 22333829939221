import { useEffect, useState } from "react";
import Header from "../shared/Header";
import Requester from '../shared/Requester'
import { Table, Button, Row, Col } from 'reactstrap'
import IconButtonDelete from "../shared/IconButtonDelete";
import { Link, useHistory, useParams } from "react-router-dom";
import ConfirmDialog from "../shared/ConfirmDialog";
import FormattedDate from "../shared/FormattedDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import FileSaver from "file-saver";
import GenerarLibroDialog from "./GenerarLibroDialog";

const LibrosList = () => {
    const [onlyActive, setOnlyActive] = useState(true)
    const [libros, setLibros] = useState([]);
    const { unidad } = useParams();
    const history = useHistory();

    function b64toBlob(b64Data) {

        var byteString = atob(b64Data);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
    
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab]);
    }

    useEffect(() => {
        if (libros) {
            const libross = libros.filter(f => f.unidad === unidad);
            console.log(libross);
            setLibros(libross);
        }
    }, [unidad])

    async function fetchLibros() {
        console.log("fetching...");
        const response = await Requester.get(`libros?onlyActive=${onlyActive}`)
        const libross = response.data.filter(f => f.unidad === unidad);
        setLibros(libross)
    }

    useEffect(() => {
        fetchLibros();
    }, [])

    useEffect(() => {
        fetchLibros();
    }, [onlyActive])


    function handleClickDelete(_id) {
        return async function () {
            await Requester.post(`libros/active`, {
                _id,
                active: false
            })
            setLibros(libros.filter(c => c._id !== _id))
        }
    }

    function toggleOnlyActive() {
        setOnlyActive(!onlyActive)
    }

    function handleRowClick(libro) {
        return function (e) {
            history.push(`libros/${libro._id}`)
        }
    }

    function handleClickDownload(id, name) {
        return async function (data) {
            if (name === 'libro') {
                const response = await Requester.post(`libros/${unidad}/${id}/generar`, data);
                FileSaver.saveAs(b64toBlob(response.data.file), response.data.name)
            }
        }
    }


    return (
        <div>
            <Header>Libros</Header>
            <div>
                <Row>
                    <Col xs='1'><strong>Libros</strong></Col>
                    <Col xs='auto'>
                        <Button color="secondary" onClick={toggleOnlyActive} active={!onlyActive}>{onlyActive ? "Mostrar todos" : "Mostrar activos"}</Button>
                    </Col>
                    <Col xs='auto'>
                        <Button color="secondary" disabled={unidad === ""} onClick={() => { history.push(`/${unidad}/libros/agregar`) }}>Agregar</Button>
                    </Col>
                </Row>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Estatus</th>
                            <th>Numero</th>
                            <th>Folio inicial</th>
                            <th>Folio final</th>
                            <th>Fecha creación</th>
                            <th>Fecha impresión</th>
                            <th>Activo</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            libros.map(l => (
                                <tr key={l._id}>
                                    <td>{l.estatus}</td>
                                    <td>{l.numero}</td>
                                    <td>{l.folioInicial}</td>
                                    <td>{l.folioFinal}</td>
                                    <td>{FormattedDate(l.fechaCreacion)}</td>
                                    <td>{l.fechaImpresion ? FormattedDate(l.fechaImpresion) : "-"}</td>
                                    <td>{l.active ? 'Si' : 'No'}</td>
                                    <td>
                                        {/* <FontAwesomeIcon icon={faFilePdf} style={{ cursor: 'pointer' }} onClick={handleClickDownload(l._id, "libro")} /> */}
                                        <GenerarLibroDialog 
                                            title="Generar Libro"
                                            onSave={handleClickDownload(l._id, "libro")}
                                        />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon={faPencilAlt} style={{ cursor: 'pointer' }} onClick={handleRowClick(l)} />
                                    </td>
                                    <td>
                                        <ConfirmDialog
                                            title="Eliminar libro"
                                            body={`Se eliminará el libro ${l.numero}`}>
                                            {
                                                confirm => (
                                                    <IconButtonDelete onClick={confirm(handleClickDelete(l._id))}></IconButtonDelete>
                                                )
                                            }
                                        </ConfirmDialog>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default LibrosList;