import { useEffect, useState } from "react";
import { Prompt, useParams } from "react-router";
import { Container, Form, FormGroup, Row, Col, Table } from "reactstrap";
import FilePicker from "../shared/FilePicker";
import Header from "../shared/Header";
import Requester from "../shared/Requester";
import usePost from "../shared/usePost";
import FormattedDatePicker from "../shared/FormattedDatePicker";
import SelectOne from "./components/SelectOne";
import Notas from "./Notas";
import moment from 'moment'
import Swal from "sweetalert2";
import AuthenticatedInput from "../shared/AuthenticatedInput";
import useReadOnly from "../shared/useReadOnly";
import FixedSubmitButton from "./components/FixedSubmitButton";
import HeaderProceso from "./components/HeaderProceso";

function allowWrite(permisos) {
  return permisos.admin || permisos['certificacion'] === 'write'
}

const Input = AuthenticatedInput(allowWrite)

export default function Certificaciones({ mode }) {

  const { unidad, procesoId } = useParams();
  const readOnly = useReadOnly(allowWrite);
  const [copiasArchivo, setCopiasArchivo] = useState({
    notas: [],
    solicitudCopias: [],
    expedicionCopias: []
  })

  const [blockNavigation, setBlockNavigation] = useState(false)
  const [saving, saveCopiasArchivo,] = usePost(`proceso/${unidad}/certificacion`)
  const [firmantes, setFirmantes] = useState([])
  const [tipo, setTipo] = useState("cotejo")

  useEffect(() => {

    async function fetchProceso() {
      const response = await Requester.get(`proceso/${unidad}/certificacion/${procesoId}`);
      const data = response.data.certificacion;
      if (data.notas) {
        data.notas = data.notas.map(n => {
          n.fecha = new Date(n.fecha)
          return n;
        })
      }
      setCopiasArchivo({ ...copiasArchivo, ...data })
    }

    async function fetchFirmantes() {
      const response = await Requester.get(`proceso/${unidad}/${procesoId}/firmantes`);
      setFirmantes([
        ...response.data.firmantes])
    }

    fetchProceso()
    fetchFirmantes()


  }, [unidad, mode, procesoId])

  function handleChange(name) {
    return function (e) {
      setBlockNavigation(true)
      setCopiasArchivo({ ...copiasArchivo, [name]: e })
    }
  }

  function getTipo(value) {
    switch (value) {
      case 'documento certificado':
        return "(DC)"
      case 'cotejo':
        return "(CJ)"
      case 'rpc':
        return "(RPC)"
      case 'avaluo':
        return "(AV)"
      default:
        return "(?)"
    }
  }

  function handleClickAgregar(e) {
    setBlockNavigation(true)
    setCopiasArchivo({
      ...copiasArchivo,
      solicitudCopias: [...copiasArchivo.solicitudCopias, {
        solicitante: e.nombre,
        tipo,
        cantidad: 1,
        fecha: new Date()
      }],
      expedicionCopias: [...copiasArchivo.expedicionCopias, {
        expedida: false,
        tipo,
        status: "elaboracion",
        libroRegistro: false
      }]
    })
  }

  function handleChangeSolicitudCopias(i, name) {
    return function (e) {
      setBlockNavigation(true)
      setCopiasArchivo({
        ...copiasArchivo,
        solicitudCopias: copiasArchivo.solicitudCopias.map((s, mi) => mi === i ? { ...s, [name]: e.target.value } : s)
      })
    }
  }

  function handleChangeExpedicionCopias(i, name) {
    return function (e) {
      setBlockNavigation(true)
      let transform = (s) => s;
      switch (name) {
        case 'expedida': {
          const fechaExpedicion = e.target.checked ? new Date() : null
          transform = (s) => ({ ...s, [name]: e.target.checked, fechaExpedicion })
          break
        }
        case 'fechaExpedicion': {
          const fechaExpedicion = e ? e : new Date()
          transform = (s) => ({ ...s, [name]: e, fechaExpedicion })
          break
        }
        case 'fechaEntrega': {
          const fechaEntrega = e ? e : new Date()
          transform = (s) => ({ ...s, [name]: e, fechaEntrega })
          break
        }
        case 'acuse': {
          transform = (s) => ({ ...s, [name]: e, fechaEntrega: new Date(), status: "entregado" })
          break;
        }
        default: {
          transform = (s) => ({ ...s, [name]: e.target.value });
        }
      }

      setCopiasArchivo({
        ...copiasArchivo,
        expedicionCopias: copiasArchivo.expedicionCopias.map((s, mi) => mi === i ? transform(s) : s)
      })
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    await saveCopiasArchivo(copiasArchivo)
    setBlockNavigation(false)
    Swal.fire({
      icon: 'success',
      title: 'Guardado',
      confirmButtonText: 'Continuar',
      confirmButtonColor: '#6c757d',
      timer: 1200
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Prompt
        when={blockNavigation}
        message={location => `¿Salir sin guardar cambios?`}
      ></Prompt>
      <HeaderProceso unidad={unidad} procesoId={procesoId}></HeaderProceso>
      <Header>
        Certificaciones, Boletas y Avalúos
      </Header>
      <FormGroup className='GreyTop'>
        <Container>
          <Row>
            <Col xs={2}><p style={{ fontWeight: 'bold', marginTop: '6px' }}>Expedir</p></Col>
            <Input type="select" value={tipo} className="FormInput" onChange={(e) => setTipo(e.target.value)} style={{ width: '300px' }}>
              <option value="cotejo">Cotejo (CJ)</option>
              <option value="documento certificado">Documento Certificado (DC)</option>
              <option value="rpc">Constancia de Inscripción RPC (RPC)</option>
              <option value="avaluo">Avalúo (AV)</option>
            </Input>
            <Col xs={1}><p style={{ fontWeight: 'bold', marginTop: '6px' }}>para</p></Col>
            <SelectOne values={firmantes} valueLabel="nombre"
              onSelect={handleClickAgregar}
              readOnly={readOnly} style={{ width: '300px' }}></SelectOne>
          </Row>
          <br />
          <Row>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Solicitante</th>
                  {/* <th>Copia para</th> */}
                  <th>Cantidad</th>
                  <th>Fecha</th>
                  <th>Agente</th>
                </tr>
              </thead>
              <tbody>
                {
                  copiasArchivo.solicitudCopias.map((s, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{getTipo(s.tipo)}</td>
                      <td>{s.solicitante}</td>
                      <td>
                        <Input type="number" className="FormInput in-table"
                          value={s.cantidad}
                          onChange={handleChangeSolicitudCopias(i, 'cantidad')}
                          required></Input>
                      </td>
                      <td>
                        {s.fecha ? moment(s.fecha).format('DD-MM-YYYY') : ''}
                      </td>
                      <td>
                        {s.agente?.nombre}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>

          </Row>
          <br />
          <Row>
            <Col><p style={{ fontWeight: 'bold' }}>Estatus de las solicitudes</p></Col>
          </Row>
          <br />
          <Row>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Expedida</th>
                  <th>Fecha expedición</th>
                  <th>Fecha entrega</th>
                  <th>Acuse</th>
                  <th>Agente</th>
                  <th>Estatus</th>
                </tr>
              </thead>
              <tbody>
                {
                  copiasArchivo.expedicionCopias.map((e, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{getTipo(e.tipo)}</td>
                      <td>
                        <Input type="checkbox"
                          checked={e.expedida}
                          onChange={handleChangeExpedicionCopias(i, 'expedida')}></Input>
                      </td>
                      <td>
                        {e.expedida &&
                          <FormattedDatePicker selected={e.fechaExpedicion}
                            onChange={handleChangeExpedicionCopias(i, 'fechaExpedicion')}
                            readOnly={readOnly}></FormattedDatePicker>
                        }
                      </td>
                      <td>
                        {e.expedida &&
                          <FormattedDatePicker selected={e.fechaEntrega}
                            onChange={handleChangeExpedicionCopias(i, 'fechaEntrega')}
                            readOnly={readOnly}></FormattedDatePicker>
                        }
                      </td>
                      <td>
                        {e.expedida &&
                          <FilePicker
                            icon
                            route={`proceso/${unidad}/certificacion/files/${copiasArchivo._id}`}
                            fileUrl={e.acuseUrl}
                            onChange={handleChangeExpedicionCopias(i, 'acuse')}
                            readOnly={readOnly}
                          >Adjuntar</FilePicker>
                        }
                      </td>
                      <td>{e.agente?.nombre}</td>
                      <td>{e.expedida &&
                        <Input type="select"
                          className="FormInput"
                          value={e.status}
                          onChange={handleChangeExpedicionCopias(i, 'status')}
                          style={{ width: '180px' }}
                        >
                          <option value="elaboracion">En elaboración</option>
                          <option value="firma">En firma</option>
                          <option value="firmado">Firmado</option>
                          <option value="entregado">Entregado</option>
                        </Input>
                      }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Row>

          <br />
          <br />
          <br />
          <br />
          <Notas
            unidad={unidad}
            tipo="certificacion" notasProceso={copiasArchivo.notas} onChange={handleChange('notas')}
            readOnly={readOnly}></Notas>
          <br />
          <br />
          <br />
          <FixedSubmitButton saving={saving} readOnly={readOnly}></FixedSubmitButton>
        </Container>
      </FormGroup>
    </Form>
  )
}