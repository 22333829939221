import { useContext, useEffect, useState } from "react";
import Header from "../shared/Header";
import Requester from '../shared/Requester'
import { Table, Button, Row, Col } from 'reactstrap'
import IconButtonDelete from "../shared/IconButtonDelete";
import { useHistory, useParams } from "react-router-dom";
import ConfirmDialog from "../shared/ConfirmDialog";
import FormattedDate from "../shared/FormattedDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import xlsx from 'node-xlsx';
import moment from 'moment';
import Swal from "sweetalert2";
import { SessionContext } from "../shared/session";

const ReportesList = () => {
    const [onlyActive, setOnlyActive] = useState(true)
    const [reportes, setReportes] = useState([]);
    const {unidad} = useContext(SessionContext)
    const history = useHistory();

    useEffect(() => {
        
        if(!unidad) {
            return;
        }

        // if (reportes) {
        //     const response = reportes.filter(f => f.unidad === unidad);
        //     console.log(response);
        //     setReportes(response);
        // }
        
        fetchReportes();
    }, [unidad])

    async function fetchReportes() {
        console.log("fetching...");
        const response = await Requester.get(`reportes/byUnidad/${unidad._id}?onlyActive=${onlyActive}`)
        // const data = response.data.filter(f => f.unidad === unidad._id);
        setReportes(response.data)
    }

    function handleClickDelete(_id) {
        return async function () {
            console.log("deleting...", _id);
            await Requester.post(`reportes/active`, {
                _id,
                active: false
            })
            setReportes(reportes.filter(c => c._id !== _id))
        }
    }

    async function handleClickEjecutar(_id) {
        console.log("ejecutando reporte...", _id);
        const { data } = await Requester.get(`reportes/ejecutar/${_id}`);

        if (data.res && data.res.length === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'El reporte no generó ningún resultado',
                confirmButtonText: 'Continuar',
                confirmButtonColor: '#6c757d'
            });
        } else {
            buildReport(data.res, data.cols, data.props, data.reportName);
        }
    }

    const saveReportFile = (reportName, byte) => {
        console.log("descargando reporte...")
        var blob = new Blob([byte], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    }

    function getDeep(object, flatProps) {
        if (typeof flatProps === 'string')
            flatProps = flatProps.split('.');

        const currentNeedle = flatProps.shift();

        if (typeof object[currentNeedle] !== 'undefined') {
            if (flatProps.length === 0) {
                if (currentNeedle.includes('Fecha') || currentNeedle.includes('fecha')) {
                    return moment(object[currentNeedle]).format('DD-MM-YYYY');
                } else
                    return object[currentNeedle];
            } else
                return getDeep(object[currentNeedle], flatProps)
        } else {
            return "-"
        }
    }

    const buildReport = (data, colsSheetData, props, nombreReporte) => {
        console.log("construyendo reporte...")
        let sheetData = [];
        const colsWidth = [];
        const maxDataLen = colsSheetData.map(d => d.length);

        data.map(d => {
            const element = [];
            for (let i = 0; i < props.length; i++) {
                const value = getDeep(d, props[i]) || '-';
                element.push(value);

                if (maxDataLen[i] < value.length) {
                    maxDataLen[i] = value.length;
                }
            }
            sheetData.push(element);
        });


        for (let i = 0; i < colsSheetData.length; i++) {
            colsWidth.push(
                { wch: maxDataLen[i] + 2 }
            );
        }

        const options = { '!cols': colsWidth };

        const dataset = [];
        dataset.push(colsSheetData);
        sheetData.map(m => {
            dataset.push(m);
        });

        const buildData = xlsx.build([{ name: nombreReporte, data: dataset }], options);
        saveReportFile(`${nombreReporte} - ${moment().utc().format('YYYYMMDD')}`, buildData);
    }

    useEffect(() => {
        fetchReportes();
    }, [])

    useEffect(() => {
        fetchReportes();
    }, [onlyActive])

    function toggleOnlyActive() {
        setOnlyActive(!onlyActive)
    }

    function handleRowClick(report) {
        return function (e) {
            history.push(`reportes/${report._id}`)
        }
    }

    return (
        <div>
            <Header>Reportes</Header>
            <div>
                <Row>
                    <Col xs='1'><strong>Reportes</strong></Col>
                    <Col xs='auto'>
                        <Button color="secondary" onClick={toggleOnlyActive} active={!onlyActive}>{onlyActive ? "Mostrar todos" : "Mostrar activos"}</Button>
                    </Col>
                    <Col xs='auto'>
                        <Button color="secondary" disabled={!unidad} onClick={() => { history.push(`/${unidad._id}/reportes/agregar`) }}>Agregar</Button>
                    </Col>
                </Row>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Fecha creación</th>
                            <th>Activo</th>
                            <th>Ejecutar</th>
                            <th>Editar</th>
                            <th>Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reportes.map(r => (
                                <tr key={r._id}>
                                    <td>{r.nombre}</td>
                                    <td>{FormattedDate(r.createdAt)}</td>
                                    <td>{r.active ? "Sí" : "No"}</td>
                                    <td>
                                        <FontAwesomeIcon icon={faFileExcel} style={{ cursor: 'pointer' }} onClick={() => handleClickEjecutar(r._id)} />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon={faPencilAlt} style={{ cursor: 'pointer' }} onClick={handleRowClick(r)} />
                                    </td>
                                    <td>
                                        <ConfirmDialog
                                            title="Eliminar reporte"
                                            body={`Se eliminará el reporte ${r.nombre}`}>
                                            {
                                                confirm => (
                                                    <IconButtonDelete onClick={confirm(handleClickDelete(r._id))}></IconButtonDelete>
                                                )
                                            }
                                        </ConfirmDialog>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default ReportesList;