import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Creatable from 'react-select/creatable';
import { Button, Col, Container, Input, Label, Row, Table } from 'reactstrap';
import IconButtonDelete from '../shared/IconButtonDelete';
import Requester from '../shared/Requester';



export default function Notas({ unidad, tipo, notasProceso, onChange, readOnly }) {

    const [notas, setNotas] = useState([])
    const [selected, setSelected] = useState(null)
    const [guardar, setGuardar] = useState(false)

    useEffect(() => {

        async function fetchNotas() {
            const response = await Requester.get(`notas/${unidad}/${tipo}`)
            setNotas(response.data.map(n => ({
                value: n,
                label: n.nota
            })))
        }

        fetchNotas();

    }, [tipo])

    function handleChangeGuardar(e) {
        setGuardar(e.target.checked)
    }

    function handleChange(value) {
        setSelected(value.value);
    }

    function getOptionValue(e) {
        return {
            label: e,
            value: {
                nota: e
            }
        }
    }

    function atenderNota(i) {
        let notas = [...notasProceso]
        notas[i].fijo = !notas[i].fijo
        onChange(notas)
    }

    function handleClickAdd() {
        if (selected) {
            onChange([...notasProceso, { ...selected, fecha: new Date(), guardar }])
            setGuardar(false)
        }
    }

    function handleClickDelete(index) {
        return function (e) {
            onChange(notasProceso.filter((n, i) => i !== index))
        }
    }

    return (
        <Container>
            <Row>
                <Col xs="2"></Col>
                <Col xs="1">
                    <Label>Notas</Label>
                </Col>
                {
                    !readOnly ?
                        <React.Fragment>
                            <Col xs="5">
                                <Creatable options={notas}
                                    onChange={handleChange}
                                    getNewOptionData={getOptionValue}></Creatable>
                            </Col>
                            <Col>
                                <Input type="checkbox"
                                    checked={guardar}
                                    onChange={handleChangeGuardar}></Input>
                                {' '}
                                <Label>Guardar</Label>
                            </Col>
                            <Col>
                                <Button onClick={handleClickAdd}>Agregar</Button>
                            </Col>
                        </React.Fragment> : null
                }
            </Row>
            <br />
            <Row>
                <Col>
                    <p style={{ fontWeight: "bold" }}>Notas</p>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Table>
                        <thead>
                            <tr>
                                <th>Atendido</th>
                                <th>Nota</th>
                                <th>Fecha</th>
                                <th>Agente</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                notasProceso ? notasProceso.map((u, i) => (
                                    <tr key={i}>
                                        <td>
                                            <Input name="notasProceso" type="checkbox" onChange={() => atenderNota(i)} checked={u.fijo} disabled={readOnly} />
                                        </td>
                                        <td>{u.nota}</td>
                                        <td>{moment(u.fecha).format("DD-MM-YYYY")}</td>
                                        <td>{u.usuario?.nombre}</td>
                                        <td>
                                            {
                                                !readOnly ? 
                                                <IconButtonDelete onClick={handleClickDelete(i)}></IconButtonDelete> : null
                                            }
                                        </td>
                                    </tr>
                                )) : null
                            }
                        </tbody>
                    </Table>
                </Col>

            </Row>
        </Container>
    )

}