import React from 'react'

import UnidadComponentLoader from './UnidadComponentLoader'

const Mediacion = React.lazy(() => import('./mediacion/Firma'))
const Correduria = React.lazy(() => import('./correduria/Firma'))

export default function InfoPreliminar(params) {

    return (                     
        <UnidadComponentLoader 
            {...params}
            correduria={Correduria}
            mediacion={Mediacion}
        ></UnidadComponentLoader>       
    )

}