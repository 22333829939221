import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Row, Table } from "reactstrap";
import ConfirmDialog from "../shared/ConfirmDialog";
import Header from "../shared/Header";
import IconButtonDelete from "../shared/IconButtonDelete";
import Requester from "../shared/Requester";


function UsuariosList() {

    const [onlyActive, setOnlyActive] = useState(true)
    const [usuarios, setUsuarios] = useState([]);
    const history = useHistory()


    useEffect(() => {

        async function fetchUsuarios() {
            const response = await Requester.get(`usuarios?onlyActive=${onlyActive}`)
            setUsuarios(response.data)
        }

        fetchUsuarios();

    }, [onlyActive])

    function handleRowClick(usuario) {
        return function (e) {
            history.push(`usuarios/${usuario._id}`)
        }
    }

    function handleClickPermisos(usuario) {
        return function(e) {
            e.stopPropagation();
            history.push(`usuarios/${usuario._id}/permisos`)
        }
    }

    function toggleOnlyActive() {
        setOnlyActive(!onlyActive)
    }

    function handleClickDelete(_id) {
        return async function() {
            await Requester.post(`usuarios/active`, {
                _id,
                active: false
            })
            setUsuarios(usuarios.filter(c => c._id !== _id))
        }
    }

    return (
        <div>
            <Header>Agentes</Header>
            <div>
                <Row>
                    <Col xs='1'><strong>Agentes</strong></Col>
                    <Col xs='auto'>
                        <Button color="secondary" onClick={toggleOnlyActive} active={!onlyActive}>Mostrar todos</Button>
                    </Col>
                    <Col xs='auto'>
                        <Link to="usuarios/agregar">
                            <Button color="secondary">Agregar</Button>
                        </Link>
                    </Col>
                </Row>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Nombre Agente</th>
                            <th>Correo</th>
                            <th>Activo</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            usuarios.map(u => (
                                <tr key={u._id} onClick={handleRowClick(u)} style={{ cursor: 'pointer' }}>
                                    <td>{u.nombre}</td>
                                    <td>{u.correo}</td>
                                    <td>{u.active ? 'Si' : 'No'}</td>
                                    <td>
                                        <div onClick={handleClickPermisos(u)}>
                                            <FontAwesomeIcon icon={faKey} style={{  cursor: 'pointer' }}></FontAwesomeIcon>
                                        </div>
                                    </td>
                                    <td>
                                        <ConfirmDialog 
                                            title="Dar de baja usuario"
                                            body={`Se dará de baja al usuario ${u.nombre}`}>
                                            {
                                                confirm => (
                                                    <IconButtonDelete onClick={confirm(handleClickDelete(u._id))}></IconButtonDelete>
                                                )
                                            }
                                        </ConfirmDialog>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )

}

export default UsuariosList