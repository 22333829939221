import { useEffect, useState } from "react";
import Header from "../shared/Header";
import Requester from '../shared/Requester'
import { Table, Button, Row, Col } from 'reactstrap'
import IconButtonDelete from "../shared/IconButtonDelete";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import ConfirmDialog from "../shared/ConfirmDialog";


function ClientesList() {

    const [onlyActive, setOnlyActive] = useState(true)
    const [clientes, setClientes] = useState([]);
    const history = useHistory()
    const {unidad} = useParams()

    useEffect(() => {

        async function fetchClientes() {
            const response = await Requester.get(`clientes?onlyActive=${onlyActive}`)
            setClientes(response.data)
        }

        fetchClientes();

    }, [onlyActive])


    function handleClickDelete(_id) {
        return async function () {
            await Requester.post(`clientes/${unidad}/active`, {
                _id,
                active: false
            })
            setClientes(clientes.filter(c => c._id !== _id))
        }
    }

    function toggleOnlyActive() {
        setOnlyActive(!onlyActive)
    }

    function handleRowClick(cliente) {
        return function (e) {
            history.push(`clientes/${cliente._id}`)
        }
    }

    return (
        <div>
            <Header>Clientes</Header>
            <div>
                <Row>
                    <Col xs='1'><strong>Clientes</strong></Col>
                    <Col xs='auto'>
                        <Button color="secondary" onClick={toggleOnlyActive} active={!onlyActive}>Mostrar todos</Button>
                    </Col>
                    <Col xs='auto'>
                        <Link to="clientes/agregar">
                            <Button color="secondary">Agregar</Button>
                        </Link>
                    </Col>
                </Row>
                <br />
                <Table>
                    <thead>
                        <tr>
                            <th>Corporativo</th>
                            <th>Razón Social</th>
                            <th>Representante</th>
                            <th>Correo</th>
                            <th>Activo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            clientes.map(c => (
                                <tr key={c._id} onClick={handleRowClick(c)} style={{ cursor: 'pointer' }}>
                                    <td>{c.nombre}</td>
                                    <td>{c.razonSocial}</td>
                                    <td>{c.representante.nombre}</td>
                                    <td>{c.representante.correo}</td>
                                    <td>{c.active ? 'Si' : 'No'}</td>
                                    <td>
                                        <ConfirmDialog
                                            title="Dar de baja cliente"
                                            body={`Se dará de baja al cliente ${c.nombre}`}>
                                            {
                                                confirm => (
                                                    <IconButtonDelete onClick={confirm(handleClickDelete(c._id))}></IconButtonDelete>
                                                )
                                            }
                                        </ConfirmDialog>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default ClientesList;