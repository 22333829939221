import { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import FilePicker from "../shared/FilePicker";
import Header from "../shared/Header";
import IconButtonDelete from "../shared/IconButtonDelete";
import Requester from "../shared/Requester";
import Subtitle from "../shared/Subtitle";
import ContactoClienteDialog from "./ContactoClienteDialog";
import Swal from "sweetalert2";
import { SessionContext } from "../shared/session";

const attrValues = {
    checkbox: 'checked',
    text: 'value'
}


function FormaCliente({ mode }) {
    
    const session = useContext(SessionContext)

    const history = useHistory()
    const {unidad} = useParams()
    const [redirect, setRedirect] = useState(true);
    const { clienteId } = useParams()
    const [cliente, setCliente] = useState({
        nombre: '',
        razonSocial: '',
        rfc: '',
        domicilio: '',
        liberacionPago: false,
        honorariosPactadosText: '',
        honorariosPactados: null,
        honorariosPactadosUrl: null,
        representantes: [],
        solicitantes: [],
        referentes: []
    })

    useEffect(() => {
        const path = window.location.pathname.split("/");
        if (path.length === 5) {
            setRedirect(false);
        }
    },[])

    useEffect(() => {

        async function fetchCliente() {
            const response = await Requester.get(`clientes/${clienteId}`)
            setCliente(response.data)
        }

        if (mode === 'edit') {
            fetchCliente();
        }

    }, [clienteId])

    function handleChange(name) {
        return function (e) {
            setCliente({ ...cliente, [name]: e.target[attrValues[e.target.type]] })
        }
    }

    function handleChangeFile(name) {
        return function (file) {
            setCliente({ ...cliente, [name]: file })
        }
    }

    function handleAddContact(name) {
        return function (data) {
            const contacts = [...cliente[name], data]
            setCliente({ ...cliente, [name]: contacts })
        }
    }

    function handleClickDeleteContact(collection, contact) {
        return function () {
            const val = contact._id ? '_id' : 'uuid'
            const newCol = cliente[collection].filter(c => c[val] !== contact[val])
            setCliente({ ...cliente, [collection]: newCol })
        }
    }

    function handleRepresentanteFileChange(fieldName, representante) {
        return function (file) {
            const val = representante._id ? '_id' : 'uuid'
            const newReps = cliente.representantes.map(r => r[val] === representante[val] ?
                { ...r, [fieldName]: file } : r);
            setCliente({ ...cliente, representantes: newReps })
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        await Requester.post(`clientes/${unidad}`, cliente);
        if (redirect)
            history.push(`/${unidad}/clientes`)

            Swal.fire({
                icon: 'success',
                title: 'Guardado',
                confirmButtonText: 'Continuar',
                confirmButtonColor: '#6c757d',
                timer: 1200
            })

    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header>Nuevo Cliente</Header>
            <FormGroup>
                <Container>
                    <Row>
                        <Col xs="3">
                            <Label for="nombre">Nombre *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="nombre" className="FormInput"
                                value={cliente.nombre}
                                onChange={handleChange('nombre')}
                                required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="razonSocial">Razon Social *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="razonSocial" className="FormInput"
                                value={cliente.razonSocial}
                                onChange={handleChange('razonSocial')} required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="rfc">RFC</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="rfc" className="FormInput"
                                value={cliente.rfc}
                                onChange={handleChange('rfc')}></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="domicilio">Domicilio Fiscal</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="domicilio" className="FormInput"
                                value={cliente.domicilio}
                                onChange={handleChange('domicilio')}></Input>
                        </Col>
                    </Row>
                    <br />
                    {session?.permisos?.admin && 
                        <Row>
                            <Col xs="3">
                                <Label for="liberacionPagos">Liberación de pagos</Label>
                            </Col>
                            <Col xs="auto">
                                <Input type="checkbox" id="liberacionPagos"
                                    checked={cliente.liberacionPago}
                                    onChange={handleChange('liberacionPago')}></Input>
                            </Col>
                        </Row> 
                    }
                    <br />
                    {session?.permisos?.admin &&
                        <Row>
                            <Col xs="3">
                                <Label for="honorarios">Honorarios Pactados</Label>
                            </Col>
                            <Col xs="auto">
                                <Input type="text" id="honorarios" className="FormInput"
                                    value={cliente.honorariosPactadosText}
                                    onChange={handleChange('honorariosPactadosText')}
                                    required></Input>
                            </Col>
                            <Col xs="1">
                                <FilePicker
                                    route={`clientes/files/${cliente._id}`}
                                    fileUrl={cliente.honorariosPactadosUrl}
                                    onChange={handleChangeFile('honorariosPactados')}>Adjuntar</FilePicker>
                            </Col>
                        </Row>
                    }
                    <br />
                    <br />
                    <Row>
                        <Col xs="6">
                            <Subtitle>Representante Legal</Subtitle>
                        </Col>
                        <Col xs="auto">
                            <ContactoClienteDialog
                                title='Agregar representante legal'
                                label='Agregar'
                                askDatosGenerales={true}
                                onSave={handleAddContact('representantes')}></ContactoClienteDialog>
                        </Col>
                    </Row>
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Correo electrónico</th>
                                    <th>Identificación</th>
                                    <th>Poder</th>
                                    <th>Datos generales</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cliente.representantes.map((r, i) => (
                                        <tr key={i}>
                                            <td>{r.nombre}</td>
                                            <td>{r.correo}</td>
                                            <td>
                                                <FilePicker
                                                    route={`clientes/files/${cliente._id}`}
                                                    fileUrl={r.identificacionUrl}
                                                    onChange={handleRepresentanteFileChange('identificacion', r)}>Adjuntar</FilePicker>
                                            </td>
                                            <td>
                                                <FilePicker
                                                    route={`clientes/files/${cliente._id}`}
                                                    fileUrl={r.poderUrl}
                                                    onChange={handleRepresentanteFileChange('poder', r)}>Adjuntar</FilePicker>
                                            </td>
                                            <td>{r.datosGenerales}</td>
                                            <td><IconButtonDelete onClick={handleClickDeleteContact('representantes', r)}></IconButtonDelete></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="6">
                            <Subtitle>Solicitantes</Subtitle>
                        </Col>
                        <Col xs="auto">
                            <ContactoClienteDialog
                                title='Agregar solicitante'
                                label='Agregar'
                                onSave={handleAddContact('solicitantes')}></ContactoClienteDialog>
                        </Col>
                    </Row>
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Correo electrónico</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cliente.solicitantes.map((r, i) => (
                                        <tr key={i}>
                                            <td>{r.nombre}</td>
                                            <td>{r.correo}</td>
                                            <td><IconButtonDelete onClick={handleClickDeleteContact('solicitantes', r)}></IconButtonDelete></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="6">
                            <Subtitle>Referentes</Subtitle>
                        </Col>
                        <Col xs="auto">
                            <ContactoClienteDialog
                                title='Agregar referente'
                                label='Agregar'
                                onSave={handleAddContact('referentes')}></ContactoClienteDialog>
                        </Col>
                    </Row>
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Correo electrónico</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cliente.referentes.map((r, i) => (
                                        <tr key={i}>
                                            <td>{r.nombre}</td>
                                            <td>{r.correo}</td>
                                            <td><IconButtonDelete onClick={handleClickDeleteContact('referentes', r)}></IconButtonDelete></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col xs="10"></Col>
                        <Col xs="2">
                            <Button color="secondary" type="submit">Guardar</Button>
                        </Col>
                    </Row>
                </Container>
            </FormGroup>
        </Form>
    )

}

export default FormaCliente;