import { useEffect, useState } from "react";
import { Prompt, useParams } from "react-router";
import { Container, Form, FormGroup, Button, Row, Col, Label } from "reactstrap";
import Header from "../shared/Header";
import DocumentosTramitacion from "./components/DocumentosTramitacion";
import Requester from "../shared/Requester";
import usePost from "../shared/usePost";
import Subtitle from "../shared/Subtitle";
import FechasFirmaTramitacion from "./components/FechasFirmaTramitacion";
import FormattedDate from "../shared/FormattedDate";
import FormattedDatePicker from "../shared/FormattedDatePicker";
import moment from "moment";
import Swal from "sweetalert2";
import AuthenticatedInput from "../shared/AuthenticatedInput";
import useReadOnly from "../shared/useReadOnly";
import FixedSubmitButton from "./components/FixedSubmitButton";
import Notas from "./Notas";
import HeaderProceso from "./components/HeaderProceso";
import FileSaver from "file-saver";
import IconButtonCustom from "../shared/IconButtonCustom";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

function allowWrite(permisos) {
    return permisos.admin || permisos['tramitacion'] === 'write'
}

const Input = AuthenticatedInput(allowWrite)

function b64ToBlob(b64Data) {

    var byteString = atob(b64Data);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab]);
}

export default function Tramitacion({ }) {

    const { unidad, procesoId } = useParams();
    const [agente, setAgente] = useState("");
    const readOnly = useReadOnly(allowWrite)
    const [tramitacion, setTramitacion] = useState({
        proyectoEnviadoFecha: {},
        documentos: [],
        documentosEnvio: [],
        fechasFirma: [],
        notas: []
    })
    const [blockNavigation, setBlockNavigation] = useState(false)
    const [saving, setSaving] = useState(false)
    const [hasFechaMaximaCJA, setHasFechaMaximaCJA] = useState(false)
    const [savingTramitacion, saveTramitacion,] = usePost(`proceso/${unidad}/tramitacion`)
    const [savingArmado, saveArmado,] = usePost(`armados/proceso`)

    useEffect(() => {

        async function fetchProceso() {
            const response = await Requester.get(`proceso/${unidad}/tramitacion/${procesoId}`);
            const data = response.data.tramitacion;

            setTramitacion({ ...tramitacion, ...data })
        }

        async function fetchTieneFechaMaximaCJA() {
            const response = await Requester.get(`proceso/${unidad}/${procesoId}/tieneCitaCJAFechaMaxima`);
            setHasFechaMaximaCJA(response.data.tieneCitaCJAFechaMaxima)
        }

        fetchProceso()
        fetchTieneFechaMaximaCJA()


    }, [unidad, procesoId])

    useEffect(() => {

        async function fetchAgente(userId) {
            const response = await Requester.get(`usuarios/${userId}`);
            setAgente(response.data.nombre)
        }
        if (tramitacion.armado?.fechaAgente?.agente) {
            fetchAgente(tramitacion.armado?.fechaAgente?.agente)
        }

    }, [tramitacion])

    useEffect(() => {

        setSaving(savingTramitacion || savingArmado)

    }, [savingTramitacion, savingArmado])

    function handleChange(name) {
        return function (e) {
            console.log(e)
            setBlockNavigation(true)
            switch (name) {
                case 'documentos':
                case 'documentosEnvio':
                case 'fechasFirma': {
                    setTramitacion({ ...tramitacion, [name]: e })
                    break;
                }
                case 'proyectoEnviado': {
                    setTramitacion({
                        ...tramitacion,
                        proyectoEnviado: e.target.checked,
                        proyectoEnviadoFecha: { ...tramitacion.proyectoEnviadoFecha, fecha: e.target.checked ? new Date() : null }
                    })
                    break;
                }
                case 'programarEnvio': {
                    setTramitacion({ ...tramitacion, [name]: moment(e).format("MM/DD/YYYY") })
                    break;
                }
                case 'notas': {
                    setTramitacion({ ...tramitacion, [name]: e })
                    return;
                }
                default: {
                    setTramitacion({ ...tramitacion, [name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
                }
            }
        }
    }


    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        await saveTramitacion(tramitacion)
        setBlockNavigation(false)
        Swal.fire({
            icon: 'success',
            title: 'Guardado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        })
    }

    async function handleClickArmado(e) {
        const response = await saveArmado({ procesoId })
        setTramitacion({ ...tramitacion, armado: response.data })
    }

    async function handleClickDownloadControlArmado(e) {
        const response = await Requester.get(`armados/${unidad}/${tramitacion.armado._id}/control/${procesoId}`);
        FileSaver.saveAs(b64ToBlob(response.data.file), response.data.name)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Prompt
                when={blockNavigation}
                message={location => `¿Salir sin guardar cambios?`}
            ></Prompt>
            <HeaderProceso unidad={unidad} procesoId={procesoId}></HeaderProceso>
            <Header>
                Tramitación y armado
            </Header>
            <FormGroup className="GreyTop">
                <Container>
                    <DocumentosTramitacion
                        tramitacionId={tramitacion._id}
                        unidad={unidad}
                        documentos={tramitacion.documentos}
                        onChange={handleChange('documentos')}
                        showIntegracion={true}
                        readOnly={readOnly}></DocumentosTramitacion>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {
                            <Row>
                                {
                                    !tramitacion.armado ?
                                        <>
                                            <Col>
                                                <Button onClick={handleClickArmado} disabled={saving || !hasFechaMaximaCJA}>Enviar a armado</Button>
                                            </Col>
                                            {
                                                !hasFechaMaximaCJA ?
                                                    <Col>
                                                        <div>Falta que se recaben todas las firmas del Convenio</div>
                                                    </Col> :
                                                    <Col>
                                                        <div style={{ width: '200px' }}>
                                                            {
                                                                `Armado: Pendiente`
                                                            }
                                                        </div>
                                                    </Col>
                                            }
                                        </>
                                        :
                                            <>
                                                <Row style={{ fontWeight: 700, textAlign: 'center', marginLeft: '30%' }}>
                                                    <Col xs={3}>Estatus Armado</Col>
                                                    <Col xs={1}>Control Armado</Col>
                                                    <Col xs={2}>Agente</Col>
                                                    <Col xs={2}>Creado</Col>
                                                </Row>
                                                <Row style={{ textAlign: 'center', marginLeft: '30%' }}>
                                                    <Col xs={3}>
                                                        <div style={{ width: '200px' }}>
                                                            <a href={`/${unidad}/armados/${tramitacion.armado._id}`} target='_blank'>
                                                                {
                                                                    `Armado: ${tramitacion.armado?.registros?.find(r => r.proceso === procesoId)?.estatus}`
                                                                }
                                                            </a>
                                                        </div>
                                                    </Col>
                                                    <Col xs={1}>
                                                        <IconButtonCustom onClick={handleClickDownloadControlArmado}
                                                            faIcon={faFileDownload}
                                                            color="black"
                                                            tooltip="Control de armado"
                                                        />
                                                    </Col>
                                                    <Col xs={2}>{agente}</Col>
                                                    <Col xs={2}>{moment(tramitacion.armado.fechaAgente?.fecha).format('DD/MM/YYYY')}</Col>
                                                </Row>
                                            </>
                                }
                            </Row>
                        }
                    </div>
                    <br />
                    <Row className="GreyTop">
                        <Col>
                            <Subtitle>Envío del proyecto</Subtitle>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col xs="2">
                            <Label>Programar envío</Label>
                        </Col>
                        <Col xs="auto">
                            <FormattedDatePicker value={moment(tramitacion.programarEnvio).format("DD/MM/YYYY")}
                                onChange={handleChange('programarEnvio')}
                                readOnly={readOnly}></FormattedDatePicker>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="2">
                            <Label>Proyecto enviado</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="checkbox"
                                checked={tramitacion.proyectoEnviado}
                                onChange={handleChange('proyectoEnviado')}></Input>
                        </Col>
                        <Col>{FormattedDate(tramitacion.proyectoEnviadoFecha?.fecha)}</Col>
                        <Col>{tramitacion.proyectoEnviadoFecha?.agente?.nombre}</Col>
                    </Row>
                    <br />
                    <br />
                    <DocumentosTramitacion
                        title="Proyecto"
                        tramitacionId={tramitacion._id}
                        unidad={unidad}
                        documentos={tramitacion.documentosEnvio}
                        onChange={handleChange('documentosEnvio')}
                        readOnly={readOnly}></DocumentosTramitacion>
                    <br />
                    <br />
                    <FechasFirmaTramitacion
                        fechasFirma={tramitacion.fechasFirma}
                        onChange={handleChange('fechasFirma')}
                        readOnly={readOnly}></FechasFirmaTramitacion>
                    <br />
                    <br />
                    <Notas unidad={unidad} tipo="tramitacion" notasProceso={tramitacion.notas} onChange={handleChange('notas')}></Notas>
                    <br />
                    <br />
                    <FixedSubmitButton saving={saving} readOnly={readOnly}></FixedSubmitButton>
                </Container>
            </FormGroup>
        </Form>
    )

}