import React, { useEffect, useState } from "react";
import { Prompt, useHistory, useParams } from "react-router";
import { Col, Container, Form, FormGroup, Row, Button } from "reactstrap";
import ConfirmDialog from "../shared/ConfirmDialog";
import Header from "../shared/Header";
import Requester from "../shared/Requester";
import usePost from "../shared/usePost";
import HeaderProceso from "./components/HeaderProceso";

export default function EliminarProceso() {

    const { unidad, procesoId } = useParams();
    const [solicitudFolio, setSolicitudFolio] = useState(null);
    const [deleting, deleteProceso,] = usePost(`proceso/${unidad}/${procesoId}/eliminar`)

    useEffect(() => {

        async function fetchProceso() {
            const response = await Requester.get(`proceso/${unidad}/solicitudFolio/${procesoId}`);
            const data = response.data.solicitudFolio;
            setSolicitudFolio({ ...data })
        }

        fetchProceso()

    }, [unidad, procesoId])

    async function handleClickDelete() {
        await deleteProceso();
        window.location = `/${unidad}/tickets`;
    }

    return (
        <Form>
            <HeaderProceso unidad={unidad} procesoId={procesoId}></HeaderProceso>
            <Header>
                Eliminar proceso
            </Header>
            <FormGroup className="GreyTop">
                <Container>
                    {
                        !solicitudFolio?.folio ?
                            <Col>
                                <p> Da click en eliminar para eliminar este proceso </p>
                                <br />
                                <br />
                                <Row>
                                    <Col xs="10"></Col>
                                    <Col xs="2">
                                        <ConfirmDialog
                                            title="Eliminar proceso"
                                            body={`Se eliminará este proceso, da click en Aceptar para continuar`}>
                                            {
                                                confirm => (
                                                    <Button color="secondary"
                                                        disabled={deleting}
                                                        onClick={confirm(handleClickDelete)}>Eliminar</Button>
                                                )
                                            }
                                        </ConfirmDialog>
                                    </Col>
                                </Row>
                            </Col>
                            : <p>
                                <strong>No es posible eliminar este proceso porque ya tiene un folio asignado</strong>
                            </p>
                    }
                </Container>
            </FormGroup>
        </Form>
    )

}