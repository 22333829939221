import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Header from "../shared/Header";
import Requester from "../shared/Requester";
import Swal from "sweetalert2";

const attrValues = {
    checkbox: 'checked',
    text: 'value',
    "select-one": 'value'
}

const FormaUnidad = () => {
    const history = useHistory()
    const { unidadId, unidad :unidadPath } = useParams()
    const [unidad, setUnidad] = useState({
        nombre: "",
        mediador: "",
        tipo: "Mediador Privado",
        prefijo: ""
    });

    async function fetchUnidad(unidadId) {
        const response = await Requester.get(`unidades/${unidadId}`)
        setUnidad(response.data)
    }

    useEffect(() => {
        if (unidadId !== undefined)
            fetchUnidad(unidadId)
    }, [unidadId])

    function handleChange(name) {
        return function (e) {
            console.log(e.target.type);
            setUnidad({ ...unidad, [name]: e.target[attrValues[e.target.type]] })
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        console.log(unidad);
        await Requester.post(`unidades/${unidadPath}`, unidad);
        Swal.fire({
            icon: 'success',
            title: 'Guardado',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#6c757d',
            timer: 1200
        }).then(() => history.push(`/${unidadPath}/unidades`))
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header>
                {
                    unidadId === undefined ? 'Nueva unidad de negocio' : 'Editar unidad de negocio'
                }
            </Header>
            <FormGroup>
                <Container>
                    <Row>
                        <Col xs="3">
                            <Label for="nombre">Nombre de la unidad *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="nombre" className="FormInput"
                                value={unidad.nombre}
                                onChange={handleChange("nombre")}
                                required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="tipo">Tipo *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="select" id="tipo" className="FormInput"
                                value={unidad.tipo}
                                onChange={handleChange("tipo")}
                                required>
                                <option value="Mediador Privado">Mediador Privado</option>
                                <option value="Correduría">Correduría</option>
                            </Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="mediador">{unidad.tipo === "Mediador Privado" ? "Mediador *" : "Corredor *"}</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="mediador" className="FormInput"
                                value={unidad.mediador}
                                onChange={handleChange("mediador")}
                                required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="3">
                            <Label for="prefijo">Prefijo de folios *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="prefijo" className="FormInput"
                                value={unidad.prefijo}
                                onChange={handleChange("prefijo")}
                                required></Input>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <Col xs="10"></Col>
                        <Col xs="2">
                            <Button color="secondary" type="submit">Guardar</Button>
                        </Col>
                    </Row>
                </Container>
            </FormGroup>
        </Form>
    );
};

export default FormaUnidad;