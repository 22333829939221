import { BrowserRouter as Router, Route, Switch, useParams, useRouteMatch } from "react-router-dom"
import ArmadosList from "./ArmadosList";
import FormaArmado from "./FormaArmado";



function Armados() {

    let match = useRouteMatch();

    return (
        <>
            <Route exact path={`${match.path}`}>
                <ArmadosList></ArmadosList>
            </Route>
            <Switch>
                <Route exact path={`${match.path}/:armadoId`}>
                    <FormaArmado></FormaArmado>
                </Route>
            </Switch>
        </>
    )
}

export default Armados