import React, { useState, useEffect } from 'react';
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import FormDialog from "../shared/FormDialog";
import { v4 as uuidv4 } from 'uuid';

const GenerarLibroDialog = ({ title, onSave }) => {
    const [data, setData] = useState({
        numeroOficio: '',
        cierre: '',
        certificacion: '',
    })

    function handleChange(name) {
        return function (e) {
            setData({ ...data, [name]: e.target.value })
        }
    }

    function handleConfirm(data) {
        onSave({ ...data })
    }

    return (
        <FormDialog
            title={title}
            data={data}
            body={
                <Container>
                    <Row>
                        <Col xs="12">
                            <Label for="numeroOficio">Número de Oficio *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="text" id="numeroOficio" className="FormInput"
                                value={data.numeroOficio}
                                onChange={handleChange('numeroOficio')}
                                required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="12">
                            <Label for="certificacion">Vigencia de Certificacion *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="date" id="certificacion" className="FormInput"
                                value={data.certificacion}
                                onChange={handleChange('certificacion')}
                                required></Input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="12">
                            <Label for="cierre">Fecha de Cierre *</Label>
                        </Col>
                        <Col xs="auto">
                            <Input type="date" id="cierre" className="FormInput"
                                value={data.cierre}
                                onChange={handleChange('cierre')}
                                required></Input>
                        </Col>
                    </Row>
                </Container>
            }>
            {
                confirm => {
                    return (<FontAwesomeIcon icon={faFilePdf} style={{ cursor: 'pointer' }} onClick={confirm(handleConfirm)} />)
                }
            }
        </FormDialog>
    )
};

export default GenerarLibroDialog;